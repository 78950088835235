'use strict';
import Frontend from '@/api/Frontend';
import Structure from '@/api/Structure';
import Connectors from '@/api/Connectors';

export default {
   namespaced: true,
   strict: true,
   state: {
      connectivities: [],
      services: [],
      transaction_types: [],
      labels: [],
      machines: [],
      categories: [],
      connectors: [],
      connector_groups: [],
      alarm_settings: [],
      cli_settings: [],
      opened_nodes: [],
      statuses: [
         { code: 10, name: 'Ok' },
         { code: 'no', name: 'Non Ok' },
         { code: 11, name: 'Modem Connesso' },
         { code: 12, name: 'Ring' },
         { code: 13, name: 'Enq/Ack' },
         { code: 14, name: 'No Host' },
         { code: 15, name: 'No Listener' },
         { code: 16, name: 'Blocked' },
         { code: 17, name: 'Blocked' },
         { code: 20, name: 'Servizio' },
         { code: 21, name: 'Servizio' }
      ]
   },
   getters: {
      getOpenedNodes: state => state.opened_nodes,
      getMachines: state => state.machines,
      getMachineByID: state => id => state.machines.find(mac => mac.codMacchina === id),
      getMachineLabels: state => id => state.labels.find(label => label.id === id),
      getUnregisteredMachines: state => {
         const macIDs = state.labels.reduce((acc, curr) => {
            acc.push(curr.id);
            return acc;
         }, []);

         return state.machines.length ? state.machines.filter(mac => !macIDs.includes(+mac.codMacchina)) : [];
      },
      getCategoryMachines: state => catArray => {
         const treeCategories = state.categories.filter(c => c.livello <= 3).map(c => c.id_label);
         const macIDs = state.labels
            .filter(mac => {
               const filteredLabels = mac.labels.filter(l => treeCategories.includes(l));
               return filteredLabels.length === catArray.length && catArray.every(val => filteredLabels.includes(val));
            })
            .reduce((acc, curr) => {
               acc.push(curr.id);
               return acc;
            }, []);

         return state.machines.filter(mac => macIDs.includes(+mac.codMacchina));
      },
      getCategoryNestedMachines: state => catArray => {
         const macIDs = state.labels
            .filter(mac => catArray.every(val => mac.labels.includes(val)))
            .reduce((acc, curr) => {
               acc.push(curr.id);
               return acc;
            }, []);

         return state.machines.filter(mac => macIDs.includes(+mac.codMacchina));
      },
      getCategories: state => state.categories,
      getConnectors: state => state.connectors.length ? state.connectors.filter(gt => gt.gt_show === 1) : [],
      getConnectorsFull: state => state.connectors,
      getConnectorGroups: state => state.connector_groups,
      getServices: state => state.services,
      getServiceByOffset: state => offset => state.services.filter(ser => ser.offsetSer === offset),
      getGroups: state => state.categories.map(cat => {
         return { id: +cat.id_gruppo, desc: cat.desc_gruppo, level: +cat.livello };
      }).filter((group, i, self) => self.findIndex(el => (el.id === group.id)) === i),
      getLevels: (state, getters) => getters.getGroups.reduce((acc, curr) => {
         const i = acc.findIndex(el => el.level === curr.level);
         if (i !== -1) {
            acc[i].categories++;
            acc[i].desc += `/${curr.desc}`;
         }
         else {
            curr.categories = 1;
            acc.push(curr);
         }
         return acc;
      }, []),
      getCategoryLevel: state => catID => state.categories.filter(cat => +cat.id_label === catID).map(cat => cat.livello)[0],
      getGroupsByLevel: (state, getters) => level => getters.getGroups.filter(group => group.level === level),
      getCategoriesByLevel: state => level => state.categories.filter(cat => +cat.livello === level),
      getCategoryBySlug: state => slug => state.categories.filter(cat => cat.slug_gruppo === slug),
      getCategoriesBySort: state => level => state.categories.filter(cat => +cat.dash_sort === level),
      getChildCategoriesByLevel: state => level => state.categories.filter(cat => +cat.livello >= level),
      getLevelByCategory: state => category => state.categories.filter(cat => +cat.id_label === category)[0].livello,
      countCategoryChildren: state => catArray => state.labels.filter(mac => catArray.every((val, i) => mac.labels[i] === val)).length,
      getCategoryStatus: state => catArray => {
         const macIDs = state.labels
            .filter(mac => catArray.every(val => mac.labels.includes(val)))
            .reduce((acc, curr) => {
               acc.push(curr.id);
               return acc;
            }, []);

         return state.machines.length ? state.machines
            .filter(mac => macIDs.includes(+mac.codMacchina))
            .reduce((acc, curr) => +curr.codStato > acc && !!+curr.abilitata ? +curr.codStato : acc, 0) : [];
      },
      getCategoryChildren: state => catArray => {
         const macIDs = state.labels
            .filter(mac => catArray.every(val => mac.labels.includes(val)))
            .reduce((acc, curr) => {
               acc.push(curr.id);
               return acc;
            }, []);

         return state.machines
            .filter(mac => macIDs.includes(+mac.codMacchina));
      },
      getAlarmSettingsByLabel: state => catID => state.alarm_settings.find(alarm => +alarm.id_label === catID),
      getCliAlarmSettingsByLabel: state => catID => state.cli_settings.find(alarm => +alarm.id_label === catID)
   },
   mutations: {
      UPDATE_CONNECTIVITIES (state, payload) {
         state.connectivities = payload;
      },
      UPDATE_SERVICES (state, payload) {
         state.services = payload;
      },
      UPDATE_TRANSACTION_TYPES (state, payload) {
         state.transaction_types = payload;
      },
      UPDATE_LABELS (state, labels) {
         state.labels = labels;
      },
      UPDATE_MACHINES (state, machines) {
         state.machines = machines;
      },
      UPDATE_CATEGORIES (state, categories) {
         state.categories = categories;
      },
      UPDATE_CONNECTORS (state, connectors) {
         state.connectors = connectors;
      },
      UPDATE_CONNECTOR_GROUPS (state, connectorGroup) {
         state.connector_groups = connectorGroup;
      },
      UPDATE_SETTINGS (state, alarmSettings) {
         state.alarm_settings = alarmSettings;
      },
      UPDATE_CLI_SETTINGS (state, alarmSettings) {
         state.cli_settings = alarmSettings;
      },
      ADD_OPENED_NODE (state, id) {
         state.opened_nodes.push(id);
      },
      REMOVE_OPENED_NODE (state, id) {
         state.opened_nodes = state.opened_nodes.filter(node => node !== id);
      }
   },
   actions: {
      async updateConnectivities ({ state, commit }) {
         // if (!state.connectivities.length) {
         const apiRes = await Structure.getAllConnectivities();
         if (apiRes.status === 200)
            commit('UPDATE_CONNECTIVITIES', apiRes.data);
         else
            console.error('Errore', apiRes.status);
         // }
      },
      async updateTransactionTypes ({ state, commit }) {
         const apiRes = await Structure.getAllTransactionTypes();
         if (apiRes.status === 200)
            commit('UPDATE_TRANSACTION_TYPES', apiRes.data);
         else
            console.error('Errore', apiRes.status);
      },
      updateMachines ({ commit }, machines) {
         if (!machines) return;
         commit('UPDATE_MACHINES', machines);
      },
      updateLabels ({ commit }, labels) {
         const remapped = labels.length ? labels.reduce((acc, curr) => {
            if (acc[curr.id_macchina])
               acc[curr.id_macchina].labels.push(+curr.id_label);
            else
               acc[curr.id_macchina] = { id: +curr.id_macchina, labels: [+curr.id_label] };

            return acc;
         }, []).filter(label => label !== null) : [];

         commit('UPDATE_LABELS', remapped);
      },
      updateCategories ({ commit }, categories) {
         commit('UPDATE_CATEGORIES', categories);
      },
      updateConnectors ({ commit }, connectors) {
         commit('UPDATE_CONNECTORS', connectors);
      },
      updateConnectorGroups ({ commit }, connectorGroups) {
         commit('UPDATE_CONNECTOR_GROUPS', connectorGroups);
      },
      async updateServices ({ state, commit }) {
         const apiRes = await Structure.getServices();
         if (apiRes.status === 200)
            commit('UPDATE_SERVICES', apiRes.data);
         else
            console.error('Errore', apiRes.status);
      },
      async refreshStructure ({ dispatch }) {
         const { data: machines } = await Frontend.getMachines();
         dispatch('updateMachines', machines);

         const { data: labels } = await Frontend.getLabels();
         dispatch('updateLabels', labels);

         const { data: categories } = await Frontend.getCategories();
         dispatch('updateCategories', categories);

         const { data: connectors } = await Frontend.getConnectors();
         dispatch('updateConnectors', connectors);

         const { data: connectorGroups } = await Connectors.getConnectorGroups();
         dispatch('updateConnectorGroups', connectorGroups);
      },
      addOpenedNode ({ commit }, id) {
         commit('ADD_OPENED_NODE', id);
      },
      removeOpenedNode ({ commit }, id) {
         commit('REMOVE_OPENED_NODE', id);
      }
   }
};
