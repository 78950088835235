<template>
   <div id="tabbar">
      <div class="tabbar-top-elements">
         <ul class="tabbar-elements">
            <li
               v-for="tab in allowedTabs"
               :key="tab.slug"
               class="tabbar-element btn btn-link ex-tooltip text-light"
               :class="{'selected': tab.slug === selectedSidebar}"
               @click="clickOnTab(tab)"
            >
               <i class="tabbar-element-icon ti ti-lg2" :class="tab.icon" />
               <span class="tabbar-element-title">{{ tab.name }}</span>
            </li>
         </ul>
      </div>

      <div class="tabbar-bottom-elements">
         <ul class="tabbar-elements">
            <li
               v-if="hasBugReport"
               class="tabbar-element btn btn-link"
               title="Segnala un problema"
               @click.prevent="showFeedModal"
            >
               <i class="tabbar-element-icon ti ti-2x ti-bug text-light" />
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
   name: 'TheTabBarBar',
   computed: {
      ...mapState({
         selectedSidebar: state => state.application.selectedSidebar
      }),
      ...mapGetters({
         tabs: 'getTabBar',
         hasBugReport: 'hasBugReport'
      }),
      userRole () {
         return this.$store.state.auth.group;
      },
      allowedTabs () {
         return this.tabs.filter(tab => tab.roles.includes(this.userRole));
      }
   },
   async created () {
      await this.updateSidebarSettings();
      await this.updateTabBarSettings();
      await this.updateHasBugReportSettings();
   },
   methods: {
      ...mapActions([
         'selectSidebar',
         'updateSidebarSettings',
         'updateTabBarSettings',
         'updateHasBugReportSettings'
      ]),
      clickOnTab (tab) {
         if (tab.url && this.$route.path !== tab.url)
            this.$router.push({ path: tab.url }).catch(() => null);

         this.selectSidebar(tab.slug);
      },
      showFeedModal () {
         this.$emit('showFeedModal');
      }
   }
};
</script>

<style lang="scss">
#tabbar {
  width: $tabbar-width;
  overflow-x: auto;
  height: 100vh;
  background: $tabbar-bg;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: fixed;
  z-index: 9;
  top: 0;

  .tabbar-top-elements {
    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  .tabbar-bottom-elements {
    padding-top: 0.5rem;
    z-index: 1;
  }

  .tabbar-elements {
    list-style: none;
    text-align: center;
    width: $tabbar-width;
    padding: 0;
    margin: 0;

    .tabbar-element {
      height: $tabbar-width;
      width: 100%;
      margin: 0;
      border-left: 2px solid transparent;
      opacity: 0.5;
      transition: opacity 0.2s;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 0;

      &:hover {
        opacity: 1;
      }

      &.selected {
        opacity: 1;
        border-left: 2px solid #fff;
      }

      .tabbar-element-title {
        font-size: 10px;
        line-height: 9px;
        padding-top: 0.4rem;
      }

      .tabbar-element-icon {
        &.badge::after {
          bottom: -10px;
          right: 0;
          position: absolute;
        }

        &.badge-update::after {
          bottom: initial;
        }
      }
    }
  }
}
</style>
