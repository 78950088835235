<template>
   <div>
      <details
         v-if="nChildren"
         class="accordion cat-node"
         :open="isNodeOpen"
      >
         <summary
            class="accordion-header cat-node-title"
            tabindex="0"
            @click.prevent="toggleOpen"
         >
            <div class="d-flex c-hand p-vcentered overflow-hidden">
               <i class="node-icon ti ti-chevron-right ti-lg" :class="{ opened: isNodeOpen }" />
               <span :class="{'has-error': catStatus > 0}" />
               <span class="text-bold cut-text">{{ category.desc_label }}</span>
            </div>
            <div
               class="d-flex cat-tools"
               :class="{ 'opened': isMenu }"
               @click.prevent.stop=""
            >
               <a class="machine-options text-dark shortcut shortcut-icon" @click="showConnectionModal">
                  <i class="ti ti-external-link mr-1" title="Monitor Lista Connessioni" />
               </a>
               <router-link :to="{name: 'report-connessioni-ora', query: { labels: chainString }}" class="machine-options text-dark shortcut shortcut-icon">
                  <i class="ti ti-chart-line mr-1" title="Report Connessioni/Ora" />
               </router-link>
               <div
                  v-click-outside="closeMenu"
                  class="machine-options structure-options icon-tools shortcut shortcut-icon"
                  title="Menù"
                  @click="openMenu($event)"
               >
                  <i class="ti ti-dots-vertical" />
                  <SidebarTreeCategoryMenu
                     v-if="isMenu"
                     ref="menu"
                     :click-event="clickEvent"
                     :is-menu="isMenu"
                     :cat-chain="catChain"
                     title=""
                     @connection-list-monitor="showConnectionModal"
                     @total-connection-monitor="openTotalConnectionsMonitor"
                     @simultaneous-monitor="openSimultaneousMonitor"
                     @close-menu="closeMenu"
                  />
               </div>
            </div>
         </summary>
         <div class="accordion-body cat-node-children">
            <ul v-show="categoryMachines.length" class="menu menu-nav p-0 pt-1">
               <SidebarTreeMachine
                  v-for="mac in categoryMachines"
                  :key="mac.codMacchina"
                  :machine="mac"
                  :cat-chain="catChain"
               />
            </ul>
            <SidebarTreeCategory
               v-for="cat in childCategories"
               :key="cat.id_label"
               :category="cat"
               :cat-chain="[...catChain, +cat.id_label]"
            />
         </div>
      </details>
      <portal to="wrapper">
         <BaseConfirmModal
            v-if="isConnectionModal"
            confirm-text="Apri Monitor"
            @confirm="openConnectionsListMonitor"
            @hide="hideConnectionModal"
         >
            <template :slot="'header'">
               Monitor Lista Connessioni
            </template>
            <div :slot="'body'">
               <form>
                  <div class="form-group">
                     <label class="form-label">Filtra per TID</label>
                     <input
                        v-model="filters.tid"
                        v-mask="'XXXXXXXX'"
                        type="text"
                        class="form-input"
                        title="Numeri e Lettere"
                        placeholder="Tutti"
                     >
                  </div>
                  <div class="form-group">
                     <label class="form-label">Filtra per Origine</label>
                     <input
                        v-model="filters.origin"
                        class="form-input"
                        type="text"
                        title="Numeri o IP"
                        placeholder="Tutti"
                     >
                  </div>
                  <div class="form-group">
                     <label class="form-label" for="service">Protocollo</label>
                     <div class="input-group">
                        <select
                           id="service"
                           v-model="selectedService"
                           class="form-select"
                        >
                           <option value="">
                              Tutti
                           </option>
                           <option
                              v-for="ser in services"
                              :key="ser.indSer"
                              :value="ser.codSer"
                           >
                              {{ ser.descSer }}
                           </option>
                        </select>
                        <select
                           v-model="includeService"
                           class="form-select"
                           :disabled="selectedService === ''"
                        >
                           <option value="1">
                              Incluso
                           </option>
                           <option value="0">
                              Escluso
                           </option>
                        </select>
                     </div>
                  </div>
               </form>
            </div>
         </BaseConfirmModal>
      </portal>
   </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapGetters, mapActions } from 'vuex';
import BaseConfirmModal from '@/components/BaseConfirmModal';
import SidebarTreeMachine from '@/components/SidebarTreeMachine';
import SidebarTreeCategoryMenu from '@/components/SidebarTreeCategoryMenu';

export default {
   name: 'SidebarTreeCategory',
   directives: {
      ClickOutside
   },
   components: {
      BaseConfirmModal,
      SidebarTreeMachine,
      SidebarTreeCategoryMenu
   },
   props: {
      category: Object,
      catChain: Array
   },
   data () {
      return {
         isNodeOpen: false,
         isMenu: false,
         isConnectionModal: false,
         clickEvent: null,
         filters: {
            port: '',
            tid: '',
            origin: ''
         },
         selectedService: '',
         includeService: '1'
      };
   },
   computed: {
      ...mapGetters({
         categories: 'structure/getCategories',
         services: 'structure/getServices',
         getCategoryMachines: 'structure/getCategoryMachines',
         getCategoryNestedMachines: 'structure/getCategoryNestedMachines',
         getChildCategoriesByLevel: 'structure/getChildCategoriesByLevel',
         countCategoryChildren: 'structure/countCategoryChildren',
         getCategoryStatus: 'structure/getCategoryStatus',
         openedNodes: 'structure/getOpenedNodes'
      }),
      level () {
         if (this.category) return +this.category.livello + 1;
         else return null;
      },
      filteredCatChain () {
         return this.categories.filter(c => c.livello <= 3 && this.catChain.includes(c.id_label));
      },
      childCategories () {
         return this.getChildCategoriesByLevel(this.level).filter(cat => !cat.solo_filtro);
      },
      categoryMachines () {
         if (this.category.livello === 3 && this.catChain.length === 3)// Se è zona all'interno del nodo
            return this.getCategoryNestedMachines(this.catChain);
         return this.getCategoryMachines(this.catChain);
      },
      nChildren () {
         return this.countCategoryChildren(this.catChain);
      },
      catStatus () {
         return this.getCategoryStatus(this.catChain);
      },
      chainString () {
         return this.catChain.join(',');
      },
      accordionID () {
         return this.catChain.join('-');
      }
   },
   created () {
      if (this.openedNodes.includes(this.chainString)) this.isNodeOpen = true;
   },
   methods: {
      ...mapActions({
         addOpenedNode: 'structure/addOpenedNode',
         removeOpenedNode: 'structure/removeOpenedNode'
      }),
      toggleOpen () {
         this.isNodeOpen = !this.isNodeOpen;
         if (this.isNodeOpen) this.addOpenedNode(this.chainString);
         else this.removeOpenedNode(this.chainString);
      },
      closeMenu () {
         if (this.isMenu) {
            setTimeout(() => {
               this.isMenu = false;
            }, 10);
         }
      },
      openMenu (event) {
         if (!this.isMenu) this.isMenu = true;
         this.clickEvent = event;
      },
      openConnectionsListMonitor () {
         this.hideConnectionModal();
         const mWidth = 1280;
         const mHeight = 700;
         const mLeft = (window.innerWidth / 2) - (mWidth / 2);
         const mTop = (window.innerHeight / 2) - (mHeight / 2);
         window.open(
            `${process.env.VUE_APP_BASE_PATH}monitor/?labels=${this.chainString}&tml=${this.filters.tid}&protocol=${this.selectedService}&include=${this.includeService}&origin=${this.filters.origin}`,
            `all-${this.filters.origin}-${this.chainString}-${this.filters.tid}-${this.selectedService}-${this.includeService}`,
            `width=${mWidth},height=${mHeight},left=${mLeft},top=${mTop},toolbar=no,location=no`
         );
      },
      openTotalConnectionsMonitor () {
         this.closeMenu();
         const mWidth = 1280;
         const mHeight = 575;
         const mLeft = (window.innerWidth / 2) - (mWidth / 2);
         const mTop = (window.innerHeight / 2) - (mHeight / 2);
         window.open(
            `${process.env.VUE_APP_BASE_PATH}monitor-connessioni/?labels=${this.chainString}`,
            `tot-${this.chainString}`,
            `width=${mWidth},height=${mHeight},left=${mLeft},top=${mTop},toolbar=no,location=no`
         );
      },
      openSimultaneousMonitor () {
         this.closeMenu();
         const mWidth = 1280;
         const mHeight = 700;
         const mLeft = (window.innerWidth / 2) - (mWidth / 2);
         const mTop = (window.innerHeight / 2) - (mHeight / 2);
         window.open(
            `${process.env.VUE_APP_BASE_PATH}monitor-simultanee/?labels=${this.chainString}`,
            `simultanee-${this.chainString}`,
            `width=${mWidth},height=${mHeight},left=${mLeft},top=${mTop},toolbar=no,location=no`
         );
      },
      hideConnectionModal () {
         this.isConnectionModal = false;
      },
      showConnectionModal () {
         this.closeMenu();

         this.filters = {
            port: '',
            tid: '',
            origin: ''
         };
         this.selectedService = '';
         this.includeService = '1';
         this.isConnectionModal = true;
      }
   }
};
</script>
<style lang="scss" scoped>
.menu {
  transform: none;
}

.shortcut {
  opacity: 0.5;
  box-shadow: none;

  &:hover {
    opacity: 1;
  }
}

.machine-options {
  transform: none;

  i::before {
    opacity: 0.7;
  }

  &:hover i::before {
    opacity: 1;
  }
}

.has-error {
  display: flex;
  align-items: center;
  align-self: center;

  &::before {
    content: '';
    height: 5px;
    width: 5px;
    background: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
  }
}
</style>
