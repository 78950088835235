import Auth from '@/api/Auth';

export default {
   namespaced: false,
   strict: true,
   state: {
      userID: null,
      username: null,
      group: null,
      authenticated: false
   },
   getters: {
      isAuthenticated (state) {
         return state.authenticated;
      },
      getUserInformations (state) {
         return {
            username: state.username,
            id: state.userID,
            initials: state.username[0].toUpperCase()
         };
      }
   },
   mutations: {
      AUTHENTICATE (state, { userID, username, group }) {
         state.authenticated = true;
         state.userID = userID;
         state.username = username;
         state.group = group;
      },
      CLEAR (state) {
         state.authenticated = false;
         state.userID = '';
         state.username = '';
      }
   },
   actions: {
      authenticate ({ commit }, payload) {
         commit('AUTHENTICATE', payload);
         localStorage.setItem('token', payload.token);
      },
      checkAuth ({ commit }) {
         return new Promise((resolve, reject) => {
            (async () => {
               let apiRes = null;
               try {
                  apiRes = await Auth.me();
               }
               catch (err) {
                  apiRes = undefined;
               }
               finally {
                  if (apiRes === undefined) { // Server Non risponde
                     commit('CLEAR');
                     localStorage.removeItem('token');
                     resolve();
                  }
                  else {
                     const { status, data } = apiRes;
                     switch (status) {
                        case 200:
                           commit('AUTHENTICATE', data);
                           resolve();
                           break;
                        default:
                           commit('CLEAR');
                           localStorage.removeItem('token');
                           resolve();
                     }
                  }
               }
            })();
         });
      },
      logout ({ commit }) {
         commit('CLEAR');
         localStorage.removeItem('token');
      }
   }
};
