export default {
   namespaced: true,
   strict: true,
   state: {
      socketStatus: 2
   },
   getters: {
      status (state) {
         return state.socketStatus;
      }
   },
   mutations: {
      CHANGE_STATUS (state, payload) {
         state.socketStatus = payload;
      }
   },
   actions: {
      socket_connect ({ commit }) {
         commit('CHANGE_STATUS', 1);
      },
      socket_disconnect ({ commit }) {
         commit('CHANGE_STATUS', 2);
      },
      socket_connect_error  ({ commit }) {
         commit('CHANGE_STATUS', 0);
      },
      socket_reconnect_error  ({ commit }) {
         commit('CHANGE_STATUS', 0);
      }
   }
};
