<template>
   <div class="modal modal-sm active">
      <a
         class="modal-overlay"
         aria-label="Close"
         @click="hideModal"
      />
      <div class="modal-container material-block">
         <div v-if="hasHeader" class="modal-header">
            <a
               class="btn btn-clear float-right"
               aria-label="Close"
               @click="hideModal"
            />
            <div class="modal-title h5">
               <slot name="header" />
            </div>
         </div>
         <div v-if="hasDefault" class="modal-header">
            <a
               class="btn btn-clear float-right"
               aria-label="Close"
               @click="hideModal"
            />
            <div class="modal-title h5">
               <slot />
            </div>
         </div>
         <div v-if="hasBody" class="modal-body">
            <a
               v-if="!hasHeader && !hasDefault"
               class="btn btn-clear float-right"
               aria-label="Close"
               @click="hideModal"
            />
            <div class="content">
               <slot name="body" />
            </div>
         </div>
         <div class="modal-footer">
            <button
               class="btn btn-link"
               @click="hideModal"
            >
               Chiudi
            </button>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'BaseAlertModal',
   computed: {
      hasHeader () {
         return !!this.$slots.header;
      },
      hasBody () {
         return !!this.$slots.body;
      },
      hasDefault () {
         return !!this.$slots.default;
      }
   },
   methods: {
      hideModal () {
         this.$emit('hide');
      }
   }
};
</script>
