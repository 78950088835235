export default {
   namespaced: true,
   strict: true,
   state: {
      problemsList: []
   },
   getters: {
      sortedProblems (state) {
         function compare (a, b) {
            if (a.codProblema > b.codProblema) return -1;
            if (a.codProblema < b.codProblema) return 1;
            return 0;
         }

         return [...state.problemsList].sort(compare);
      },
      count: state => state.problemsList.length,
      getProblemsByMachine: state => macId => state.problemsList.filter(el => el.codMacchina === macId)
   },
   mutations: {
      UPDATE_PROBLEMS (state, payload) {
         state.problemsList = payload;
      }
   },
   actions: {
      'socket_problems' ({ commit }, data) {
         commit('UPDATE_PROBLEMS', data);
      }
   }
};
