<template>
   <header id="app-header">
      <nav id="header-nav" class="navbar p-0">
         <div class="navbar-section pl-2">
            <div class="app-logo">
               <button
                  id="sidebar-toggle"
                  title="Comprimi/Espandi Menu"
                  @click.stop="toggleSidebar"
               >
                  <i class="ti ti-arrow-bar-left ti-lg" :class="{'collapsed' : isCollapsedSidebar}" />
               </button>
               <router-link to="/" class="navbar-brand app-brand p-2 m-0">
                  <img
                     src="../assets/images/logo.png"
                     height="30"
                     class="mr-1"
                  >
                  <span class="text-dark mb-0 app-name">
                     {{ appTitle }}
                  </span>
               </router-link>
            </div>
         </div>
         <div class="navbar-section pr-2 navbar-tools">
            <NotificationsPanel :has-new-window="true" />
            <div class="dropdown dropdown-right">
               <button class="btn btn-link dropdown-toggle d-flex text-dark" tabindex="0">
                  <span class="header-username">{{ userInfo.username }}</span>
                  <figure
                     class="avatar avatar-sm mr-1"
                     :data-initial="userInfo.initials"
                  >
                     <i class="avatar-presence online" />
                  </figure>
               </button>
               <ul class="menu">
                  <li v-if="groupDescription" class="menu-item text-bold text-center c-default text-uppercase p-0">
                     <span class="label d-block label-primary p-1 pl-0">
                        <i class="ti ti-user-check ti-lg pr-1" />
                        {{ groupDescription }}
                     </span>
                  </li>
                  <li v-if="loginMode === 'database'" class="menu-item">
                     <router-link
                        :to="'/profile'"
                        class="menu-item-link"
                     >
                        Cambia password
                        <i class="ti ti-lg ti-key" />
                     </router-link>
                  </li>
                  <li class="menu-item">
                     <a class="menu-item-link c-hand" @click.prevent="logout">
                        Logout
                        <i class="ti ti-lg ti-logout" />
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </nav>
   </header>
</template>

<script>
import Auth from '@/api/Auth';
import Users from '@/api/Users';
import NotificationsPanel from '@/components/NotificationsPanel';
import { mapGetters } from 'vuex';

export default {
   name: 'TheHeader',
   components: {
      NotificationsPanel
   },
   props: {
      isCollapsedSidebar: Boolean
   },
   data () {
      return {
         groupDescription: null
      };
   },
   computed: {
      ...mapGetters({
         appTitle: 'getAppName',
         loginMode: 'getLoginMode'
      }),
      userInfo () {
         return this.$store.getters.getUserInformations;
      },
      userRole () {
         return this.$store.state.auth.group;
      }
   },
   async mounted () {
      const { data: groupsRequest } = await Users.getGroupsList();
      if (groupsRequest.length)
         this.groupDescription = groupsRequest.find(group => group.livello === this.userRole).descGruppo;
   },
   methods: {
      toggleSidebar () {
         this.$emit('toggleSidebar');
      },
      showFeedModal () {
         this.$emit('showFeedModal');
      },
      async logout () {
         await Auth.logout();
         this.$router.push('/login').catch(() => null);
      }
   }
};
</script>

<style lang="scss" scoped>

a:hover {
  text-decoration: none;
}

#app-header {
  position: fixed;
  pointer-events: none;
  width: 100%;
  z-index: 2;
  transition: padding-left 0.2s;
  padding-left: $tabbar-width;

  .navbar-tools {
    z-index: 9;
  }

  .menu {
    min-width: 200px;
  }
}

.app-logo {
  height: $header-height;
  display: flex;
  justify-content: space-between;
}

.app-brand {
  display: flex;
  align-items: center;
  box-shadow: none;
}

.app-name {
  display: none;
}

#header-nav {
  pointer-events: all;
  height: $header-height;
  box-shadow: 0 1.6px 3px rgba(0, 0, 0, 0.12);
  background: $light-color;
}

#header-nav .nav-item {
  padding-left: 10px;
}

.header-tools {
  width: calc(100% - 280px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.menu-item-link {
  justify-content: space-between;
  display: flex !important;
  align-items: center;
}

.header-username {
  font-size: 18px;
  margin-right: 10px;
}

.alarms-menu {
  position: relative;
}

#sidebar-toggle {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

@media (min-width: 600px) {
  .app-name {
    display: block;
  }
}

@media (min-width: 960px) {
  #app-header {
    padding-left: $sidebar-width+$tabbar-width;
  }

  #sidebar-toggle {
    display: flex;

    i {
      transform: rotate(0deg);

      &.collapsed {
        transform: rotate(180deg);
      }
    }
  }

  .sidebar-collapse #app-header {
    padding-left: 3rem;
  }
}
</style>
