'ise strict';
import Settings from '@/api/Settings';

export default {
   namespaced: false,
   strict: true,
   state: {
      menus: {},
      tab_bar: [],
      has_bug_report: null
   },
   getters: {
      getSidebarElements: state => menuName => menuName in state.menus ? state.menus[menuName] : [],
      getTabBar: state => state.tab_bar,
      hasBugReport: state => state.has_bug_report
   },
   mutations: {
      SET_SIDEBAR (state, payload) {
         state.menus = payload;
      },
      SET_TABBAR (state, payload) {
         state.tab_bar = payload;
      },
      SET_BUG_REPORT (state, payload) {
         state.has_bug_report = payload === 'true';
      }
   },
   actions: {
      async updateSidebarSettings ({ commit }) {
         const { status, data } = await Settings.getAppSettings('sidebar_menus');
         if (status === 200)
            commit('SET_SIDEBAR', data);
         else
            console.log('Errore', status);
      },
      async updateTabBarSettings ({ commit }) {
         const { status, data } = await Settings.getAppSettings('sidebar_tabs');
         if (status === 200)
            commit('SET_TABBAR', data);
         else
            console.log('Errore', status);
      },
      async updateHasBugReportSettings ({ commit }) {
         const { status, data } = await Settings.getAppSettings('has_bug_report');
         if (status === 200)
            commit('SET_BUG_REPORT', data);
         else
            console.log('Errore', status);
      }
   }
};
