<template>
   <nav class="main-sidebar sidebar p-0">
      <div class="sidebar-navigation">
         <div
            v-for="(group, groupName, groupKey) of sidebarGroups"
            :key="groupKey"
         >
            <div class="sidebar-group-header">
               <div class="text-bold m-0">
                  {{ group.title }}
               </div>
            </div>

            <SidebarGroup :group-elements="group.elements" :group-key="groupName" />
         </div>
      </div>
   </nav>
</template>

<script>
import SidebarGroup from '@/components/SidebarGroup';

export default {
   name: 'SidebarMenu',
   components: {
      SidebarGroup
   },
   props: {
      menuName: String
   },
   computed: {
      sidebarGroups () {
         return this.$store.getters.getSidebarElements(this.menuName).filter(group => {
            return group.roles.includes(this.userRole);
         });
      },
      userRole () {
         return this.$store.state.auth.group;
      }
   }
};
</script>

<style lang="scss" scoped>
  a:hover {
    text-decoration: none;
  }

  .main-sidebar.sidebar {
    left: -300px;
    transition: all 0.2s;
    background: $sidebar-bg;
    overflow: auto;
    box-shadow: 0 50px 3px rgba(0, 0, 0, 0.12);
  }

  .sidebar-show .main-sidebar {
    left: $tabbar-width;
  }

  .sidebar-collapse .main-sidebar {
    width: 0;
  }

  .sidebar {
    position: fixed;
    height: 100%;
    width: $sidebar-width;
    top: 0;
    background: $body-bg;
    z-index: 8;
  }

  .sidebar-group-header {
    padding: 0.7rem 0.8rem 0.3rem;
    opacity: 0.7;
    transition: opacity 0.2s;

    > div {
      white-space: nowrap;
    }
  }

  @media (min-width: 992px) {
    .sidebar.main-sidebar {
      left: $tabbar-width;
    }
  }
</style>
