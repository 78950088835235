<template>
   <BaseMenu :click-event="clickEvent" @close-context="$emit('close-menu')">
      <div class="context-element" @click.prevent.stop="">
         <span class="d-flex"><i class="ti ti-external-link pr-1" /> Monitor</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click="$emit('close-menu')">
            <div class="context-element" @click="$emit('connection-list-monitor')">
               <span class="d-flex"><i class="ti ti-external-link pr-1" /> Lista Connessioni</span>
            </div>
            <div class="context-element" @click="$emit('total-connection-monitor')">
               <span class="d-flex"><i class="ti ti-external-link pr-1" /> Totali Connessioni</span>
            </div>
            <div class="context-element" @click="$emit('simultaneous-monitor')">
               <span class="d-flex"><i class="ti ti-external-link pr-1" /> Simultanee</span>
            </div>
         </div>
      </div>
      <div class="context-element" @click.prevent.stop="">
         <span class="d-flex"><i class="ti ti-search pr-1" /> Ricerche</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click="$emit('close-menu')">
            <router-link :to="{name: 'lista-connessioni', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-list-search pr-1" /> Lista Connessioni</span>
            </router-link>
            <router-link :to="{name: 'lista-simultanee', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-list-search pr-1" /> Lista Simultanee</span>
            </router-link>
         </div>
      </div>
      <div class="context-element" @click.prevent.stop="">
         <span class="d-flex"><i class="ti ti-report pr-1" /> Report</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click="$emit('close-menu')">
            <router-link :to="{name: 'report-connessioni', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Connessioni</span>
            </router-link>
            <router-link :to="{name: 'report-connessioni-ora', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-chart-line pr-1" /> Connessioni/Ora</span>
            </router-link>
            <router-link :to="{name: 'report-pagamenti-ora', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-chart-line pr-1" /> Pagamenti/Ora</span>
            </router-link>
            <router-link :to="{name: 'report-transazioni', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Transazioni</span>
            </router-link>
            <router-link :to="{name: 'report-simultanee-ora', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Simultanee Orarie</span>
            </router-link>
            <router-link :to="{name: 'report-simultanee-minuto', query: { labels: chainString }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Simultanee al Minuto</span>
            </router-link>
         </div>
      </div>
   </BaseMenu>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseMenu from '@/components/BaseMenu';

export default {
   name: 'SidebarTreeCategoryMenu',
   components: {
      BaseMenu
   },
   props: {
      clickEvent: [MouseEvent, null],
      catChain: {
         type: Array,
         default: () => []
      },
      isMenu: Boolean
   },
   data () {
      return {
         basePath: process.env.VUE_APP_BASE_PATH,
         isSubMenu: '',
         isConnectionModal: false,
         localEnabled: false,
         customersList: [],
         filters: {
            port: '',
            tid: '',
            origin: ''
         },
         errorMsg: '',
         selectedService: '',
         includeService: '1'
      };
   },
   computed: {
      ...mapGetters({
         services: 'structure/getServices'
      }),
      chainString () {
         return this.catChain.join(',');
      }
   },
   watch: {
      isMenu () {
         this.isSubMenu = '';
      }
   },
   methods: {
      openSubMenu (sub) {
         if (this.isSubMenu !== sub)
            this.isSubMenu = sub;
         else
            this.isSubMenu = '';
      },
      showConnectionModal () {
         fetch(`${this.basePath}customers/getcustomers`, {
            credentials: 'same-origin'
         })
            .then(response => response.json())
            .then(data => {
               this.customersList = data;
            });
         this.isConnectionModal = true;
      },
      hideErrorModal () {
         this.errorMsg = '';
      },
      openConnectionMonitor () {
         const url = `${this.basePath}monitor/?labels=${this.chainString}&type=conn&opt=ori:${this.filters.origin},tid:${this.filters.tid},sons:,vendor:${this.filters.port},type:${this.selectedService},inc:${this.includeService}`;

         const windowName = `Connessioni-${this.chainString}${this.filters.origin}${this.filters.tid}${this.filters.port}`;
         const specs = 'location=0, menubar=0, scrollbars=1, titlebar=0, status=0, width=1350, height=400';
         window.open(url, windowName, specs);
      },
      openTotConnMonitor () {
         const url = `${this.basePath}monitor/?labels=${this.chainString}&type=mactot`;

         const windowName = `TotaleConnessioni-${this.chainString}`;
         const specs = 'location=0, menubar=0, scrollbars=1, titlebar=0, status=0, width=1350, height=400';
         window.open(url, windowName, specs);
      },
      openSimMonitor () {
         const url = `${this.basePath}monitor/?labels=${this.chainString}&type=sim`;

         const windowName = `Simultanee-${this.chainString}`;
         const specs = 'location=0, menubar=0, scrollbars=1, titlebar=0, status=0, width=1350, height=400';
         window.open(url, windowName, specs);
      },
      hideConnectionModal () {
         this.isConnectionModal = false;
      }
   }
};
</script>

<style scoped>
.option-wrap {
  display: block;
  right: -25%;
  position: absolute;
  user-select: none;
}

.option-menu {
  display: block;
  background: #fcfcfc;
  border-radius: 3px;
  cursor: default;
  width: 130px;
  box-shadow: 0 5px 10px #0003;
  border: 1px solid #ccc;
  z-index: 9;
}

.option-menu::before {
  left: 86% !important;
}

.option-menu li {
  font-size: 14px;
  padding: 0;
  transition: background 0.1s;
}

.sub-toggle a {
  display: flex !important;
  justify-content: space-between;
  cursor: pointer;
}

.option-menu li a {
  display: block;
  padding: 5px 8px;
  color: #000 !important;
  cursor: pointer;
}

.option-sub {
  font-weight: 300;
  background: #cbcbcb;
  transition: background 0.1s;
}

.option-sub li:hover {
  background: #adadad;
}

.sub-toggle a span {
  transition: transform 0.1s;
}

.sub-toggle a span.opened {
  transform: rotate(180deg);
}

.block-50 {
  padding: 0;
}

.radio-block label {
  align-items: center;
}
</style>
