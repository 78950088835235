<template>
   <div
      class="dropdown dropdown-right notifications mr-4 no-mobile"
      :class="{'active':isMenu || monitorMode, 'is-monitor-mode': monitorMode}"
      @click.prevent.stop="isMenu = true"
   >
      <a
         v-if="isMenu && !monitorMode"
         class="notifications-overlay"
         @click.prevent.stop="isMenu = false"
      />
      <a
         v-if="!monitorMode"
         class="btn btn-link dropdown-toggle notifications-button text-dark s-circle"
         :class="{'badge': notifications.length}"
         :data-badge="notificationsCount"
      >
         <i v-if="notifications.length" class="ti ti-bell-ringing" />
         <i v-else class="ti ti-bell" />
      </a>
      <div class="menu">
         <div
            v-if="notifications.length"
            class="btn-group btn-group-block mb-3"
            style="flex-wrap: nowrap;"
         >
            <button
               v-for="alarmFilter in alarmFilters"
               :key="alarmFilter.slug"
               class="btn text-bold notifications-filter-button"
               :class="{'active': filter === alarmFilter.slug}"
               @click="filter = alarmFilter.slug"
            >
               {{ alarmFilter.description }} <span class="notifications-counter">{{ filteredNotificationsCount(alarmFilter.slug) }}</span>
            </button>
            <button
               v-if="hasNewWindow"
               class="btn btn-action btn-primary new-window-button tooltip tooltip-left"
               data-tooltip="Apri in una finestra separata"
               @click.prevent.stop="openNotificationMonitor"
            >
               <i class="ti ti-external-link ti-lg" />
            </button>
         </div>

         <div v-if="!filteredNotifications.length" class="tile tile-centered p-vcentered">
            <div class="tile-icon">
               <div class="alarm-tile-icon">
                  <i class="ti ti-info-circle text-dark ti-2x centered" />
               </div>
            </div>
            <div class="tile-content">
               <div class="tile-title text-bold pr-2">
                  Nessuna notifica presente
               </div>
            </div>
         </div>

         <div
            v-for="notification in filteredNotifications"
            :key="notification.id"
            class="tile tile-centered p-vcentered"
            tabindex="0"
         >
            <div class="tile-icon">
               <div class="alarm-tile-icon" :class="getIcon(notification.type).background">
                  <i class="ti ti-2x centered" :class="getIcon(notification.type).icon" />
               </div>
               <span
                  v-if="notification.type === 'mac-alarm'"
                  class="alarm-badge"
                  :class="`wng-${notification.level}`"
                  title="Numero notifiche"
               >{{ notification.notifications }}</span>
               <span
                  v-else-if="notification.type === 'problem'"
                  class="alarm-badge ti"
                  :class="getProblemStatus(notification.status)"
                  title="Status"
               />
            </div>
            <div class="tile-content">
               <div class="tile-title text-bold">
                  {{ notification.description }}
               </div>
               <small class="tile-subtitle">
                  <span>{{ notification.group | uppercase }}</span>
                  <span>{{ notification.datetime | dateTime }}</span>
               </small>
            </div>
            <button
               v-if="['mac-alarm', 'problem'].includes(notification.type)"
               class="btn btn-link tooltip tooltip-left"
               data-tooltip="Prendi in carico"
               @click="showHandleModal(notification)"
            >
               <i class="ti ti-lg ti-tool text-dark" />
            </button>
         </div>
      </div>
      <portal to="wrapper">
         <ConfirmModal
            v-if="isHandleModal"
            @confirm="handleNotification"
            @hide="hideHandleModal"
         >
            <template :slot="'header'">
               Presa in carico
            </template>
            <div :slot="'body'">
               <div v-if="selectedNotification" class="mb-2">
                  Confermi la presa in carico di <b>{{ selectedNotification.description }}<b>?
                  </b></b>
               </div>

               <div class="form-group">
                  <label class="form-label" for="problem-note">Note</label>
                  <input
                     id="problem-note"
                     v-model="handleNote"
                     class="form-input"
                     type="text"
                     placeholder="Note di presa in carico"
                     maxlength="100"
                  >
               </div>
            </div>
         </ConfirmModal>
      </portal>
   </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Alarms from '@/api/Alarms';
import Problems from '@/api/Problems';
import ConfirmModal from '@/components/BaseConfirmModal';

export default {
   name: 'NotificationsPanel',
   components: {
      ConfirmModal
   },
   filters: {
      dateTime: date => {
         return moment(date).format('HH:mm:ss - DD/MM/YYYY');
      },
      uppercase: val => {
         if (typeof val === 'string')
            return val.toUpperCase();
      }
   },
   props: {
      hasNewWindow: {
         type: Boolean,
         default: false
      },
      monitorMode: {
         type: Boolean,
         default: false
      }
   },
   data () {
      return {
         basePath: process.env.VUE_APP_BASE_PATH,
         initNotifications: false,
         isMenu: false,
         isRefreshing: false,
         isHandleModal: false,
         selectedNotification: null,
         handleNote: '',
         permission: false,
         filter: 'all',
         alarmFilters: [
            { slug: 'all', description: 'TUTTO' },
            { slug: 'fepmonitor', description: 'COMPONENTI' },
            { slug: 'problems', description: 'PROBLEMI' },
            { slug: 'mac-alarms', description: 'ALLARMI MACCHINA' }
         ]
      };
   },
   computed: {
      ...mapGetters({
         alarms: 'alarms/getAlarms',
         macAlarms: 'alarms/sortedAlarms',
         problems: 'problems/sortedProblems'
      }),
      notifications () {
         return [
            ...this.alarms.map(alarm => {
               return {
                  id: `a-${alarm.id_allarme}`,
                  orgID: alarm.id_allarme,
                  type: 'alarm',
                  description: alarm.desc_allarme,
                  group: `Allarme ${alarm.desc_gruppo}`,
                  datetime: alarm.data_creazione
               };
            }),
            ...this.macAlarms
               .filter(alarm => !alarm.presaInCarico && alarm.abilitata)
               .map(alarm => {
                  return {
                     id: `am-${alarm.codAllarmeA}`,
                     orgID: alarm.codAllarmeA,
                     type: 'mac-alarm',
                     description: `${alarm.descMacchina}: ${alarm.descAllarme}`,
                     group: 'Allarme macchina',
                     notifications: alarm.numNotifiche,
                     datetime: alarm.dataCreazione,
                     level: alarm.wngLevel
                  };
               }),
            ...this.problems
               .filter(problem => !problem.presaInCarico && problem.abilitata)
               .map(problem => {
                  return {
                     id: `p-${problem.codProblema}`,
                     orgID: problem.codProblema,
                     type: 'problem',
                     description: `${problem.descMacchina}: ${problem.descStato}`,
                     group: 'Problema macchina',
                     datetime: problem.dataCreazione,
                     status: problem.codStato
                  };
               })
         ].sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
      },
      filteredNotifications () {
         return this.notifications.filter(notification => {
            if (this.filter === 'all')
               return true;
            if (this.filter === 'fepmonitor')
               return notification.type === 'alarm';
            if (this.filter === 'problems')
               return notification.type === 'problem';
            if (this.filter === 'mac-alarms')
               return notification.type === 'mac-alarm';
         });
      },
      notificationsCount () {
         return this.notifications.length < 100 ? this.notifications.length : '99+';
      }
   },
   watch: {
      notifications (newVal, oldVal) {
         this.isRefreshing = false;
         if (this.initNotifications && this.permission !== 'denied') {
            const newNotifications = newVal.filter(({ id: id1 }) => !oldVal.some(({ id: id2 }) => id2 === id1));
            newNotifications.forEach(notification => {
               this.$notification.show(notification.description, {
                  body: `${notification.group.toUpperCase()} - ${notification.description}`,
                  icon: require('../assets/images/logo.png'),
                  silent: false
               }, {});
            });
         }
      }
   },
   async created () {
      this.permission = await this.$notification.requestPermission();
   },
   mounted () {
      setTimeout(() => {
         this.initNotifications = true;
      }, 500);
   },
   methods: {
      ...mapActions([
         'showAlertModal'
      ]),
      getIcon (type) {
         switch (type) {
            case 'problem':
               return { icon: 'ti-server', background: 'bg-error-light text-light' };
            case 'mac-alarm':
               return { icon: 'ti-server', background: 'bg-warning' };
            case 'alarm':
               return { icon: 'ti-urgent', background: 'bg-error text-light' };
            default:
               return { icon: 'ti-alert-circle', background: 'bg-warning' };
         }
      },
      getProblemStatus (status) {
         switch (status) {
            case 9000:
               return 'ti-equal ti-rotate-90 bg-error text-light';
            case 9500:
               return 'ti-player-stop bg-error text-light';
            default:
               return 'ti-question-mark bg-error';
         }
      },
      filteredNotificationsCount (filter) {
         return this.notifications.filter(notification => {
            if (filter === 'all')
               return true;
            if (filter === 'fepmonitor')
               return notification.type === 'alarm';
            if (filter === 'problems')
               return notification.type === 'problem';
            if (filter === 'mac-alarms')
               return notification.type === 'mac-alarm';
         }).length;
      },
      refreshAlarms () {
         if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.$socket.mac_alarms.emit('refreshMacAlarms');
         }
      },
      refreshProblems () {
         if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.$socket.problems.emit('refreshProblems');
         }
      },
      showHandleModal (notification) {
         this.selectedNotification = notification;
         this.isHandleModal = true;
      },
      hideHandleModal () {
         this.handleNote = '';
         this.selectedNotification = null;
         this.isHandleModal = false;
      },
      handleNotification () {
         if (this.selectedNotification.type === 'mac-alarm')
            return this.handleMacAlarm();
         else if (this.selectedNotification.type === 'problem')
            return this.handleMacProblem();
      },
      async handleMacAlarm () {
         const apiRes = await Alarms.handleAlarm({ alarmID: this.selectedNotification.orgID, note: this.handleNote });
         this.refreshAlarms();
         this.hideHandleModal();

         if (apiRes === 'timeout')
            this.showAlertModal('Timeout del server');
         else {
            const { status } = apiRes;

            switch (status) {
               case 404:
                  this.showAlertModal('Notifica non più presente');
                  break;
               case 500:
                  this.showAlertModal('Errore server');
                  break;
            }
         }
      },
      async handleMacProblem () {
         const apiRes = await Problems.handleProblem({ problemID: this.selectedNotification.orgID, note: this.handleNote });
         this.refreshProblems();
         this.hideHandleModal();

         if (apiRes === 'timeout')
            this.showAlertModal('Timeout del server');
         else {
            const { status } = apiRes;

            switch (status) {
               case 404:
                  this.showAlertModal('Notifica non più presente');
                  break;
               case 500:
                  this.showAlertModal('Errore server');
                  break;
            }
         }
      },
      openNotificationMonitor () {
         this.isMenu = false;
         const url = `${this.basePath}notifications/`;
         const windowName = 'Notifications';
         const specs = 'location=0, menubar=0, scrollbars=1, resizable=0, titlebar=0, status=0, width=800, height=500';
         window.open(url, windowName, specs);
      }
   }
};
</script>

<style lang="scss" scoped>
.notifications-overlay {
  background: transparent;
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.notifications {
  .notifications-button {
    height: 30px;
    width: 30px;
    font-size: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.badge {
      &::after {
        background: #e85600;
        top: 5px;
        right: 5px;
      }
    }
  }

  .menu {
    max-height: 55vh;
    width: 50vw;

    .new-window-button {
      flex: 0;
      padding: 0.25rem 0.4rem;
    }

    .notifications-filter-button {
      display: flex;
      justify-content: center;
      align-items: center;

      .notifications-counter {
        margin-left: 0.4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
        color: $light-color;
        background: #e85600;
        padding: 0 0.2rem;
        border-radius: 0.5rem;
        min-width: 0.75rem;
        height: 0.75rem;
      }

      &.active .notifications-counter {
        background: $light-color;
        color: #e85600;
        font-weight: 900;
      }
    }

    .tile {
      padding: 0 0.4rem;
      border-radius: $border-radius;
      transition: background 0.2s;
      cursor: default;

      & + .tile {
        margin-top: 0.4rem;
      }

      &:hover,
      &:focus {
        background: $body-bg;
      }

      .tile-subtitle {
        display: flex;
        justify-content: space-between;
      }
    }

    .tile-icon {
      position: relative;

      .alarm-badge {
        position: absolute;
        font-size: 0.6rem;
        bottom: -2px;
        right: -5px;
        border-radius: 0.5rem;
        min-width: 0.9rem;
        height: 0.9rem;
        line-height: 1;
        box-shadow: 0 0 0 0.1rem $light-color;
        text-align: center;
        white-space: nowrap;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .alarm-tile-icon {
        align-content: space-around;
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        -ms-flex-align: center;
        -ms-flex-line-pack: distribute;
        height: 2rem;
        width: 2rem;
      }
    }
  }
}

.is-monitor-mode {
  display: block;
  position: initial;

  .menu {
    width: 100vw;
    height: 100vh;
    transform: none;
    max-height: 100vh;
    position: initial;
  }
}

// @media (min-width: 960px) {
//   .notifications {
//     .menu {
//       max-height: 65vh;
//       width: 35vw;
//     }
//   }
// }

@media (min-width: 1281px) {
  .notifications {
    .menu {
      max-height: 65vh;
      width: 35vw;
    }
  }
}
</style>
