import { httpClient } from '@/libs/httpClient';

export default class {
   static getAllConnectivities () {
      return httpClient.get('/api/structure/connectivities');
   }

   static getAllServices () {
      return httpClient.get('/api/structure/services');
   }

   static getServices () {
      return httpClient.get('/api/structure/services');
   }

   static getCommands () {
      return httpClient.get('/api/structure/commands');
   }

   static runCommand (params) {
      return httpClient.put('/api/structure/commands', params);
   }

   // TIPI TRANSAZIONE
   static getAllTransactionTypes () {
      return httpClient.get('/api/structure/transactiontypes');
   }

   static addTransaction (params) {
      return httpClient.post('/api/structure/transactiontypes', params);
   }

   static editTransaction (params) {
      return httpClient.put('/api/structure/transactiontypes', params);
   }

   static deleteTransaction (params) {
      return httpClient.delete(`/api/structure/transactiontypes/${params.id}/${params.description}`);
   }

   // HOLDING
   static getAllHoldings (params) {
      return httpClient.get('/api/structure/holdings', { params });
   }

   static getHoldingsTotal (params) {
      return httpClient.get('/api/structure/holdings/totals', { params });
   }

   static addHolding (params) {
      return httpClient.post('/api/structure/holdings', params);
   }

   static editHolding (params) {
      return httpClient.put('/api/structure/holdings', params);
   }

   static deleteHolding (id) {
      return httpClient.delete(`/api/structure/holdings/${id}`);
   }

   // CATENE
   static getAllCatene (params) {
      return httpClient.get('/api/structure/catene', { params });
   }

   static getAllCateneConfig (params) {
      return httpClient.get('/api/structure/catene/config', { params });
   }

   static getCatenaParents (params) {
      return httpClient.get('/api/structure/catene/parents', { params });
   }

   static getCateneTotal (params) {
      return httpClient.get('/api/structure/catene/totals', { params });
   }

   static addCatena (params) {
      return httpClient.post('/api/structure/catene', params);
   }

   static editCatena (params) {
      return httpClient.put('/api/structure/catene', params);
   }

   static saveCatenaInfos (params) {
      return httpClient.put('/api/structure/catena/info', params);
   }

   static deleteCatena (id) {
      return httpClient.delete(`/api/structure/catene/${id}`);
   }

   // NEGOZI
   static getAllNegozi (params) {
      return httpClient.get('/api/structure/negozi', { params });
   }

   static getAllNegoziHour (params) {
      return httpClient.get('/api/structure/negozi/hour', { params });
   }

   static getAllNegoziConfig (params) {
      return httpClient.get('/api/structure/negozi/config', { params });
   }

   static getNegozioParents (params) {
      return httpClient.get('/api/structure/negozi/parents', { params });
   }

   static getNegoziTotal (params) {
      return httpClient.get('/api/structure/negozi/totals', { params });
   }

   static editNegozio (params) {
      return httpClient.put('/api/structure/negozio', params);
   }

   static saveNegozioInfos (params) {
      return httpClient.put('/api/structure/negozio/info', params);
   }

   static deleteNegozio (id) {
      return httpClient.delete(`/api/structure/negozio/${id}`);
   }

   // POS
   static getAllPOS (params) {
      return httpClient.get('/api/structure/pos', { params });
   }

   static getPOSParents (params) {
      return httpClient.get('/api/structure/pos/parents', { params });
   }

   static getPOSTotal (params) {
      return httpClient.get('/api/structure/pos/totals', { params });
   }

   static deletePOS (id) {
      return httpClient.delete(`/api/structure/pos/${id}`);
   }

   static uploadPOS (params) {
      return httpClient.post('/api/structure/pos/', params, {
         headers: { 'Content-Type': 'multipart/form-data' }
      });
   }
};
