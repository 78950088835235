<template>
   <footer id="app-footer" class="material-block bg-white py-2 pr-0">
      <div class="container">
         <div class="columns">
            <div class="column col-6">
               <span class="text-bold">Fepcom s.r.l.</span> &copy; {{ new Date().getFullYear() }}
            </div>
            <div class="column col-6 text-right">
               <span class="text-bold">v</span>{{ version }}
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
export default {
   name: 'TheFooter',
   data () {
      return {
         version: process.env.VUE_APP_VERSION
      };
   }
};
</script>

<style>
  #app-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>
