<template>
   <li class="cat-mac">
      <div class="cat-mac-title" tabindex="0">
         <div class="d-flex overflow-hidden">
            <span v-html="statusIcon" />
            <span
               class="cat-mac-text"
               :class="{'gray': !+machine.abilitata}"
               v-html="highlightWord(machine.descMacchina)"
            />
         </div>
         <div class="d-flex cat-tools" :class="{'opened': isMenu}">
            <a class="machine-options text-dark shortcut shortcut-icon" @click="showConnectionModal">
               <i class="ti ti-external-link mr-1" title="Monitor Lista Connessioni" />
            </a>
            <router-link :to="{name: 'report-connessioni-ora', query: { macID: machine.codMacchina }}" class="machine-options text-dark shortcut shortcut-icon">
               <i class="ti ti-chart-line mr-1" title="Report Connessioni/Ora" />
            </router-link>
            <div
               v-click-outside="closeMenu"
               class="machine-options structure-options mr-1 shortcut shortcut-icon"
               title="Menù"
               @click="openMenu($event)"
            >
               <i class="ti ti-dots-vertical" />
               <SidebarTreeMachineMenu
                  v-if="isMenu"
                  ref="menu"
                  :machine="machine"
                  :cat-chain="catChain"
                  :click-event="clickEvent"
                  title=""
                  @connection-list-monitor="showConnectionModal"
                  @total-connection-monitor="openTotalConnectionsMonitor"
                  @simultaneous-monitor="openSimultaneousMonitor"
                  @show-info-modal="showInfoModal"
                  @show-edit-modal="showEditModal"
                  @show-delete-modal="showDeleteModal"
                  @show-confirm-command="confirmCommand"
                  @close-menu="closeMenu"
               />
            </div>
         </div>
      </div>
      <portal to="wrapper">
         <BaseConfirmModal
            v-if="isConnectionModal"
            confirm-text="Apri Monitor"
            @confirm="openConnectionsListMonitor"
            @hide="hideConnectionModal"
         >
            <template :slot="'header'">
               Monitor Lista Connessioni
            </template>
            <div :slot="'body'">
               <form>
                  <div class="form-group">
                     <label class="form-label">Filtra per TID</label>
                     <input
                        v-model="filters.tid"
                        v-mask="'XXXXXXXX'"
                        type="text"
                        class="form-input"
                        title="Numeri e Lettere"
                        placeholder="Tutti"
                     >
                  </div>
                  <div class="form-group">
                     <label class="form-label">Filtra per Origine</label>
                     <input
                        v-model="filters.origin"
                        class="form-input"
                        type="text"
                        title="Numeri o IP"
                        placeholder="Tutti"
                     >
                  </div>
                  <div class="form-group">
                     <label class="form-label" for="service">Protocollo</label>
                     <div class="input-group">
                        <select
                           id="service"
                           v-model="selectedService"
                           class="form-select"
                        >
                           <option value="">
                              Tutti
                           </option>
                           <option
                              v-for="ser in services"
                              :key="ser.indSer"
                              :value="ser.codSer"
                           >
                              {{ ser.descSer }}
                           </option>
                        </select>
                        <select
                           v-model="includeService"
                           class="form-select"
                           :disabled="selectedService === ''"
                        >
                           <option value="1">
                              Incluso
                           </option>
                           <option value="0">
                              Escluso
                           </option>
                        </select>
                     </div>
                  </div>
               </form>
            </div>
         </BaseConfirmModal>
      </portal>

      <portal to="wrapper">
         <BaseConfirmModal
            v-if="isInfoModal"
            :cancel-text="'Chiudi'"
            :show-confirm="false"
            @hide="hideInfoModal"
         >
            <template slot="header">
               Info Macchina
            </template>
            <div slot="body">
               <div class="mb-2">
                  <b>Nome Macchina: </b><br>{{ machine.descMacchina }}
               </div>
               <div class="mb-2">
                  <b>Codice Macchina:</b><br>{{ machine.codMacchinaMsg }}
               </div>
               <div class="mb-2">
                  <b>Caratteristiche: </b><br>
                  <div v-html="getLabels(machine.labels)" />
               </div>
               <div class="mb-2">
                  <b>Indirizzo IP: </b><br>{{ machine.Ipadd }}
               </div>
               <div class="mb-2">
                  <b>Ultimo Working: </b><br>{{ machine.workingDateTime | dateTime }}
               </div>
               <div class="mb-2">
                  <b>SMS: </b>{{ machine.sms ? 'Sì' : 'No' }} <small>(Start: {{ machine.smsStart }} - Stop: {{ machine.smsStop }})</small>
               </div>
               <div>
                  <b>Email:</b> {{ machine.email ? 'Sì' : 'No' }} <small>(Start: {{ machine.emailStart }} - Stop: {{ machine.emailStop }})</small>
               </div>
               <div v-if="machine.info" class="mt-2">
                  <b>Info:</b><br>
                  {{ machine.info }}
               </div>
            </div>
         </BaseConfirmModal>
      </portal>

      <portal to="wrapper">
         <BaseConfirmModal
            v-if="isEditModal"
            confirm-text="Salva"
            cancel-text="Annulla"
            size="modal-md"
            @hide="hideEditModal"
            @confirm="updateMachine"
         >
            <template :slot="'header'">
               Modifica Macchina
            </template>
            <div :slot="'body'">
               <form
                  ref="editForm"
                  autocomplete="off"
                  @submit="updateMachine"
               >
                  <div class="form-group">
                     <label class="form.label">Codice Apparato</label>
                     <input
                        :value="localMachine.codMacchinaMsg"
                        class="form-input"
                        maxlength="15"
                        readonly
                        type="text"
                     >
                  </div>
                  <div class="form-group">
                     <label class="form-label">Descrizione Macchina</label>
                     <input
                        v-model="localMachine.descMacchina"
                        class="form-input"
                        maxlength="40"
                        type="text"
                     >
                  </div>
                  <div class="columns">
                     <div
                        v-for="level in levels"
                        v-show="selectedLevels[`l1`] || level.level === 1"
                        :key="level.id"
                        class="form-group column col-sm-12 col-auto pb-2"
                     >
                        <label class="form-label">{{ level.desc }}</label>
                        <select
                           v-model="selectedLevels[`l${level.level}`]"
                           class="form-select"
                           @change="changeLabel($event, level.level)"
                        >
                           <option :value="false">
                              No
                           </option>
                           <option
                              v-for="cat in getCategoriesByLevel(level.level)"
                              :key="cat.id_label"
                              :value="cat.id_label"
                           >
                              {{ level.categories > 1 ? `${cat.desc_gruppo} - ` : '' }} {{ cat.desc_label }}
                           </option>
                        </select>
                     </div>
                  </div>
                  <div class="form-group columns">
                     <div class="column col-sm-12 col-3">
                        <label class="form-label">Invia SMS</label>
                        <select v-model="localMachine.sms" class="form-select">
                           <option value="0">
                              No
                           </option>
                           <option value="1">
                              Sì
                           </option>
                        </select>
                     </div>
                     <div class="column col-sm-12 col-auto">
                        <label class="form-label">SMS Inizio</label>
                        <input
                           v-model="localMachine.smsStart"
                           v-mask="'##:##'"
                           placeholder="OO:MM"
                           required
                           type="time"
                           class="form-input"
                        >
                     </div>
                     <div class="column col-sm-12 col-auto">
                        <label class="form-label">SMS Fine</label>
                        <input
                           v-model="localMachine.smsStop"
                           v-mask="'##:##'"
                           placeholder="OO:MM"
                           required
                           type="time"
                           class="form-input"
                        >
                     </div>
                  </div>
                  <div class="form-group columns">
                     <div class="column col-sm-12 col-3">
                        <label class="form-label">Invia Email</label>
                        <select v-model="localMachine.email" class="form-select">
                           <option value="0">
                              No
                           </option>
                           <option value="1">
                              Sì
                           </option>
                        </select>
                     </div>
                     <div class="column col-sm-12 col-auto">
                        <label class="form-label">Email Inizio</label>
                        <input
                           v-model="localMachine.emailStart"
                           v-mask="'##:##'"
                           placeholder="OO:MM"
                           required
                           type="time"
                           class="form-input"
                        >
                     </div>
                     <div class="column col-sm-12 col-auto">
                        <label class="form-label">Email Fine</label>
                        <input
                           v-model="localMachine.emailStop"
                           v-mask="'##:##'"
                           placeholder="OO:MM"
                           required
                           type="time"
                           class="form-input"
                        >
                     </div>
                  </div>
                  <div class="form-group">
                     <label class="form-label">Info Macchina</label>
                     <textarea v-model="localMachine.info" class="form-input" />
                  </div>
                  <div class="form-group">
                     <label class="form-switch">
                        <input v-model="localMachine.abilitata" type="checkbox">
                        <i class="form-icon" /> {{ localMachine.abilitata ? 'Abilitata' : 'Disabilitata' }}
                     </label>
                  </div>
               </form>
            </div>
         </BaseConfirmModal>
      </portal>
      <portal to="wrapper">
         <BaseConfirmModal
            v-if="isDeleteModal"
            @confirm="deleteMachine"
            @hide="hideDeleteModal"
         >
            <template :slot="'header'">
               Eliminazione macchina
            </template>
            <div :slot="'body'">
               <div class="columns">
                  <div class="d-flex p-centered mb-4">
                     <i class="ti ti-4x ti-alert-triangle text-error" />
                  </div>
                  <div class="text-center">
                     Confermi di voler eliminare la macchina <b>{{ machine.descMacchina }}</b>?
                  </div>
               </div>
            </div>
         </BaseConfirmModal>
      </portal>
      <portal to="wrapper">
         <BaseConfirmModal
            v-if="isCmdConfirmModal"
            @confirm="launchCommand"
            @hide="hideCmdConfirmModal"
         >
            <template :slot="'header'">
               Esecuzione comando
            </template>
            <div slot="body">
               Confermi di voler eseguire il comando "<b>{{ cmdName }}</b>" su "<b>{{ machine.descMacchina }}</b>"?"
            </div>
         </BaseConfirmModal>
      </portal>
   </li>
</template>

<script>
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import { mapGetters, mapActions } from 'vuex';
import SidebarTreeMachineMenu from '@/components/SidebarTreeMachineMenu';
import BaseConfirmModal from '@/components/BaseConfirmModal';
import Frontend from '@/api/Frontend';
import Structure from '@/api/Structure';

export default {
   name: 'SidebarTreeMachine',
   directives: {
      ClickOutside
   },
   filters: {
      dateTime: date => {
         return moment(date).format('DD/MM/YYYY - HH:mm:ss');
      }
   },
   components: {
      SidebarTreeMachineMenu,
      BaseConfirmModal
   },
   props: {
      machine: Object,
      catChain: {
         type: Array,
         default: () => []
      },
      searchTerm: {
         type: String,
         default: () => ''
      }
   },
   data () {
      return {
         isMenu: false,
         isInfoModal: false,
         isEditModal: false,
         isDeleteModal: false,
         localMachine: {},
         selectedLevels: {},
         isConnectionModal: false,
         cmdName: '',
         isCmdConfirmModal: false,
         selectedCmd: null,
         clickEvent: null,
         filters: {
            port: '',
            tid: '',
            origin: ''
         },
         selectedService: '',
         includeService: '1'
      };
   },
   computed: {
      ...mapGetters({
         levels: 'structure/getLevels',
         services: 'structure/getServices',
         categories: 'structure/getCategories',
         getCategoriesByLevel: 'structure/getCategoriesByLevel',
         getLevelByCategory: 'structure/getLevelByCategory',
         getMachineLabels: 'structure/getMachineLabels'
      }),
      statusIcon () {
         switch (+this.machine.codStato) {
            case 0:
               return '<span title="Connessa" class="ti ti-player-play ti-bold text-success mr-1"></span>';
            case 9000:
               return '<span title="Non Operativa" class="ti ti-equal ti-rotate-90 ti-bold text-error mr-1"></span>';
            case 9500:
               return '<span title="Non Connessa" class="ti ti-player-stop ti-bold text-error mr-1"></span>';
            default:
               return `<span title="Status ${+this.machine.codStato}" class="ti ti-question-mark ti-bold mr-1"></span>`;
         }
      }
   },
   created () {
      this.localMachine = Object.assign({}, this.machine);
      if (this.localMachine.sms > 1) this.localMachine.sms = 1;
      if (this.localMachine.email > 1) this.localMachine.email = 1;
      this.localMachine.abilitata = +this.localMachine.abilitata;
   },
   methods: {
      ...mapActions({
         refreshStructure: 'structure/refreshStructure'
      }),
      changeLabel (event, level) {
         const value = event.target.value === 'false' ? false : +event.target.value;
         this.selectedLevels[`l${level}`] = value;
      },
      getLabels () {
         try {
            const { labels } = this.getMachineLabels(codMacchina);

            const labelsDesc = [];

            for (const label of labels) {
               const category = this.categories.find(cat => cat.id_label === label);
               labelsDesc.push(category.desc_label);
            }

            return labelsDesc.join(', ');
         }
         catch (error) {
            return 'IN ATTIVAZIONE';
         }
      },
      highlightWord (string) {
         if (this.searchTerm) {
            const regexp = new RegExp(`(${this.searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
            return string.replace(regexp, '<span class="text-highlight">$1</span>');
         }
         else
            return string;
      },
      initMachineLabels () {
         const labels = this.getMachineLabels(this.localMachine.codMacchina);

         const obj = {};

         for (const level of this.levels)
            obj[`l${level.level}`] = false;

         if (labels) {
            for (const id of labels.labels) {
               const level = this.getLevelByCategory(id);
               obj[`l${level}`] = id;
            }
         }

         this.selectedLevels = obj;
      },
      closeMenu () {
         this.isMenu = false;
      },
      openMenu (event) {
         if (!this.isMenu) this.isMenu = true;
         this.clickEvent = event;
      },
      openConnectionsListMonitor () {
         this.hideConnectionModal();
         const mWidth = 1280;
         const mHeight = 700;
         const mLeft = (window.innerWidth / 2) - (mWidth / 2);
         const mTop = (window.innerHeight / 2) - (mHeight / 2);
         window.open(
            `${process.env.VUE_APP_BASE_PATH}monitor/?macID=${this.machine.codMacchina}&tml=${this.filters.tid}&protocol=${this.selectedService}&include=${this.includeService}&origin=${this.filters.origin}`,
            `all-${this.filters.origin}-${this.machine.codMacchina}-${this.filters.tid}-${this.selectedService}-${this.includeService}`,
            `width=${mWidth},height=${mHeight},left=${mLeft},top=${mTop},toolbar=no,location=no`
         );
      },
      openTotalConnectionsMonitor () {
         this.closeMenu();
         const mWidth = 1280;
         const mHeight = 500;
         const mLeft = (window.innerWidth / 2) - (mWidth / 2);
         const mTop = (window.innerHeight / 2) - (mHeight / 2);
         window.open(
            `${process.env.VUE_APP_BASE_PATH}monitor-connessioni/?macID=${this.machine.codMacchina}`,
            `tot-${this.machine.codMacchina}`,
            `width=${mWidth},height=${mHeight},left=${mLeft},top=${mTop},toolbar=no,location=no`
         );
      },
      openSimultaneousMonitor () {
         this.closeMenu();
         const mWidth = 1280;
         const mHeight = 700;
         const mLeft = (window.innerWidth / 2) - (mWidth / 2);
         const mTop = (window.innerHeight / 2) - (mHeight / 2);
         window.open(
            `${process.env.VUE_APP_BASE_PATH}monitor-simultanee/?macID=${this.machine.codMacchina}`,
            `simultanee-${this.machine.codMacchina}`,
            `width=${mWidth},height=${mHeight},left=${mLeft},top=${mTop},toolbar=no,location=no`
         );
      },
      showInfoModal () {
         this.isInfoModal = true;
      },
      hideInfoModal () {
         this.isInfoModal = false;
      },
      showEditModal () {
         this.initMachineLabels();
         this.isEditModal = true;
      },
      hideEditModal () {
         this.isEditModal = false;
      },
      showDeleteModal () {
         this.isDeleteModal = true;
      },
      hideDeleteModal () {
         this.isDeleteModal = false;
      },
      hideCmdConfirmModal () {
         this.isCmdConfirmModal = false;
         this.cmdName = '';
         this.selectedCmd = '';
      },
      confirmCommand (cmd) {
         this.cmdName = cmd.descComando;
         this.isCmdConfirmModal = true;
         this.selectedCmd = cmd.codComandoSrv;
      },
      async launchCommand () {
         const args = {
            cmd: this.selectedCmd,
            cmdName: this.cmdName,
            macID: this.machine.codMacchina,
            macIDMsg: this.machine.codMacchinaMsg,
            macName: this.machine.descMacchina
         };

         const apiRes = await Structure.runCommand(args);

         if (apiRes === 'timeout')
            this.$store.dispatch('showAlertModal', 'Timeout del server!');
         else {
            const { status } = apiRes;

            switch (status) {
               case 200:
                  break;
               case 404:
                  break;
               case 500:
                  this.$store.dispatch('showAlertModal', 'Errore del server!');
                  break;
               default:
                  break;
            }
         }

         this.hideCmdConfirmModal();
      },
      async updateMachine () {
         const labels = this.selectedLevels.l1 ? Object.values(this.selectedLevels).filter(label => label) : [];
         const {
            codMacchina,
            descMacchina,
            abilitata,
            sms,
            smsStart,
            smsStop,
            email,
            emailStart,
            emailStop,
            info
         } = this.localMachine;

         const params = {
            codMacchina: +codMacchina,
            descMacchina,
            abilitata: +abilitata,
            sms: +sms,
            smsStart,
            smsStop,
            email: +email,
            emailStart,
            emailStop,
            info,
            labels
         };

         this.hideEditModal();

         const apiRes = await Frontend.updateMachine(params);

         if (apiRes === 'timeout')
            this.$store.dispatch('showAlertModal', 'Timeout del server!');
         else {
            const { status } = apiRes;

            switch (status) {
               case 200:
                  break;
               case 404:
                  break;
               case 500:
                  this.$store.dispatch('showAlertModal', 'Errore del server!');
                  break;
               default:
                  break;
            }
         }

         this.refreshStructure();
      },
      async deleteMachine () {
         const {
            codMacchina,
            descMacchina
         } = this.localMachine;

         const params = {
            id: +codMacchina,
            description: descMacchina
         };

         this.hideDeleteModal();

         const apiRes = await Frontend.deleteMachine(params);

         if (apiRes === 'timeout')
            this.$store.dispatch('showAlertModal', 'Timeout del server!');
         else {
            const { status } = apiRes;

            switch (status) {
               case 200:
                  break;
               case 404:
                  break;
               case 500:
                  this.$store.dispatch('showAlertModal', 'Errore del server!');
                  break;
               default:
                  break;
            }
         }

         this.refreshStructure();
      },
      hideConnectionModal () {
         this.isConnectionModal = false;
      },
      showConnectionModal () {
         this.closeMenu();

         this.filters = {
            port: '',
            tid: '',
            origin: ''
         };
         this.selectedService = '';
         this.includeService = '1';
         this.isConnectionModal = true;
      }
   }
};
</script>

<style lang="scss" scoped>
.gray {
  position: relative;
}

.shortcut {
  opacity: 0.5;
  box-shadow: none;

  &:hover {
    opacity: 1;
  }
}

.machine-options {
  transform: none;

  i::before {
    opacity: 0.7;
  }

  &:hover i::before {
    opacity: 1;
  }
}

.gray::after {
  content: '';
  display: block;
  background-color: #868686;
  height: 1px;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 50%;
}

.radio-block label {
  align-items: center;
}
</style>
