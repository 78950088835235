<template>
   <div
      v-if="$store.getters.isAuthenticated"
      id="wrapper"
      :class="sidebarClass"
   >
      <portal-target name="wrapper" />
      <TheHeader
         :is-collapsed-sidebar="isCollapsedSidebar"
         @toggleSidebar="toggleSidebar"
         @showFeedModal="showFeedModal"
      />
      <div class="sidebar-wrapper" tabindex="0">
         <TheTabBar @showFeedModal="showFeedModal" />
         <TheSidebar />
      </div>
      <div id="main-content">
         <BaseLoaderLayer
            id="main-loader"
            :is-loading="isLoading"
            :opacity="0"
         />
         <slot />
      </div>
      <TheFooter />
      <a class="c-hand page-overlay" @click="hideMobileSidebar" />
      <FeedbackModal v-if="isFeedbackModal" @hideFeedModal="hideFeedModal" />
   </div>
</template>
<script>

import TheHeader from '@/components/TheHeader.vue';
import TheTabBar from '@/components/TheTabBar.vue';
import TheSidebar from '@/components/TheSidebar.vue';
import TheFooter from '@/components/TheFooter.vue';
import BaseLoaderLayer from '@/components/BaseLoaderLayer.vue';
import FeedbackModal from '@/components/FeedbackModal.vue';

export default {
   name: 'DefaultPage',
   components: {
      TheHeader,
      TheTabBar,
      TheSidebar,
      TheFooter,
      BaseLoaderLayer,
      FeedbackModal
   },
   data () {
      return {
         isCollapsedSidebar: false,
         isFeedbackModal: false,
         window: {
            width: 0,
            height: 0
         }
      };
   },
   computed: {
      sidebarClass () {
         if (this.isCollapsedSidebar && this.window.width >= 992)
            return 'sidebar-collapse';
         else if (this.isCollapsedSidebar && this.window.width < 992)
            return 'sidebar-show';
         else
            return '';
      },
      isLoading () {
         return this.$store.state.application.isLoading;
      }
   },
   created () {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
   },
   destroyed () {
      window.removeEventListener('resize', this.handleResize);
   },
   methods: {
      toggleSidebar () {
         this.isCollapsedSidebar = !this.isCollapsedSidebar;
      },
      hideMobileSidebar () {
         if (!this.isCollapsedSidebar || this.window.width >= 992) return;
         this.isCollapsedSidebar = false;
      },
      handleResize () {
         this.window.width = window.innerWidth;
         this.window.height = window.innerHeight;
      },
      showFeedModal () {
         this.isFeedbackModal = true;
      },
      hideFeedModal () {
         this.isFeedbackModal = false;
      }
   }

};
</script>

<style lang="scss">
  #wrapper {
    min-height: 100%;
    padding-bottom: 60px;
    position: relative;
  }

  #main-content {
    padding-top: $header-height;
    padding-left: $tabbar-width;
  }

  #app-footer {
    padding-left: $tabbar-width;
  }

  .page-overlay {
    opacity: 0;
    display: none;
    transition: all 0.9s;
    z-index: 3;
  }

  .sidebar-show .page-overlay {
    display: block;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
  }

  :not(.sidebar-collapse) {
    .sidebar-wrapper:hover .main-sidebar {
      width: $sidebar-width !important;
      left: $tabbar-width;
    }
  }

  @media (hover: none) {
    :not(.sidebar-collapse) {
      .sidebar-wrapper:focus-within .main-sidebar,
      .sidebar-wrapper:focus .main-sidebar {
        width: $sidebar-width !important;
        left: $tabbar-width;
      }
    }
  }

  @media (min-width: 992px) {
    #main-content,
    #app-footer,
    #main-loader {
      padding-left: $sidebar-width+$tabbar-width;
      transition: padding-left 0.2s;
    }

    .sidebar-collapse #main-content,
    .sidebar-collapse #app-footer,
    .sidebar-collapse #main-loader {
      padding-left: 3rem;
    }
  }
</style>
