import { httpClient } from '@/libs/httpClient';

export default class {
   static getConnectors () {
      return httpClient.get('/api/connectors/');
   }

   static getConnectorGroups () {
      return httpClient.get('/api/connectors/groups');
   }

   static getHalfHour (connectorID, date) {
      return httpClient.get(`/api/connectors/halfhour/${connectorID}/${date || ''}`);
   }

   static getHour (params) {
      return httpClient.get('/api/connectors/hour/', { params });
   }
};
