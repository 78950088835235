<template>
   <div :class="{'active': isVisible}" class="modal">
      <a
         class="modal-overlay"
         aria-label="Close"
         @click="hideFeedModal"
      />
      <div class="modal-container material-block">
         <div class="modal-header">
            <button
               class="btn btn-clear float-right"
               aria-label="Close"
               @click="hideFeedModal"
            />
            <div class="modal-title h5">
               Segnala un problema
            </div>
         </div>
         <div class="modal-body">
            <div class="content">
               <form autocomplete="off">
                  <div class="form-group">
                     <label class="form-label">Email</label>
                     <input
                        v-model="email"
                        type="email"
                        class="form-input"
                        placeholder="Indirizzo email"
                     >
                     <small class="form-text text-muted">Per un eventuale contatto.</small>
                  </div>
                  <div class="form-group">
                     <label class="form-label">Descrizione del problema</label>
                     <textarea
                        v-model="description"
                        class="form-input"
                        rows="10"
                        placeholder="Spiega cosa comporta il problema e come è possibile replicarlo."
                     />
                  </div>
                  <div class="form-group">
                     <label class="form-label mt-2">Allega screenshot</label>
                     <input
                        type="file"
                        accept="image/*"
                        class="form-input"
                        @change="filesChange($event)"
                     >
                  </div>
               </form>
            </div>
         </div>
         <div class="modal-footer">
            <button class="btn btn-primary mr-2" @click="sendFeedback">
               Invia
            </button>
            <button class="btn btn-link" @click="hideFeedModal">
               Annulla
            </button>
         </div>
      </div>
   </div>
</template>

<script>
import html2canvas from 'html2canvas';
import Feedback from '@/api/Feedback';

export default {
   name: 'FeedbackModal',
   data () {
      return {
         email: '',
         description: '',
         images: null,
         datetime: new Date(),
         hiddenScreenshot: null,
         isVisible: true,
         fileToUpload: null
      };
   },
   computed: {
      clientInfo () {
         const {
            appName,
            appCodeName,
            appVersion,
            buildID,
            cookieEnabled,
            hardwareConcurrency,
            language,
            onLine,
            oscpu,
            platform,
            product,
            productSub,
            userAgent
         } = window.navigator;

         return {
            appName,
            appCodeName,
            appVersion,
            buildID,
            cookieEnabled,
            hardwareConcurrency,
            language,
            onLine,
            oscpu,
            platform,
            product,
            productSub,
            userAgent
         };
      },
      userInfo () {
         return this.$store.getters.getUserInformations;
      }
   },
   methods: {
      hideFeedModal () {
         this.$emit('hideFeedModal');
      },
      sendFeedback () {
         this.isVisible = false;
         const formData = new FormData();

         formData.append('user', this.userInfo.username);
         formData.append('email', this.email);
         formData.append('description', this.description);
         formData.append('datetime', this.datetime);
         formData.append('clientInfo', JSON.stringify(this.clientInfo));
         formData.append('location', window.location);

         if (this.fileToUpload)
            formData.append('screenshot', this.fileToUpload.file, this.fileToUpload.name);

         setTimeout(async () => {
            const canvas = await this.printScreen();

            formData.append('canvas', canvas.toDataURL('image/png'));

            const apiRes = await Feedback.sendFeedback(formData);

            if (apiRes === 'timeout')
               this.$store.dispatch('showAlertModal', 'Timeout del server');
            else {
               const { status } = apiRes;

               switch (status) {
                  case 200:
                     this.successMessage = 'Upload avvenuto con successo!';
                     break;
                  case 404:
                     this.$store.dispatch('showAlertModal', 'API non trovata');
                     break;
                  case 500:
                     this.$store.dispatch('showAlertModal', 'Errore server');
                     break;
               }
            }

            this.isVisible = true;
            this.hideFeedModal();
         }, 200);
      },
      printScreen () {
         return html2canvas(document.getElementById('wrapper'));
      },
      filesChange (event) {
         const { files } = event.target;
         if (!files.length) return;

         this.fileToUpload = { name: files[0].name, file: files[0] };
      }
   }
};
</script>

<style scoped>
  #app-feedback {
    z-index: 1;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s;
  }

  .modal-content {
    z-index: 10;
  }
</style>
