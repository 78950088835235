import 'fullscreen-polyfill';
import Vue from 'vue';

import App from '@/App.vue';
import router from '@/routes';
import store from '@/store';
import VueMask from 'v-mask';
import PortalVue from 'portal-vue';
import resize from 'vue-resize-directive';
import VueNativeNotification from 'vue-native-notification';

import '@/scss/app-main.scss';

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(resize);
Vue.use(PortalVue);
Vue.use(VueNativeNotification, {
   requestOnNotify: true
});

new Vue({
   render: h => h(App),
   store,
   router
}).$mount('#app');
