<template>
   <div class="modal active" :class="size">
      <a
         class="modal-overlay"
         aria-label="Close"
         @click="hideModal"
      />
      <div class="modal-container material-block">
         <div v-if="hasHeader" class="modal-header">
            <a
               class="btn btn-clear float-right"
               aria-label="Close"
               @click="hideModal"
            />
            <div class="modal-title h5">
               <slot name="header" />
            </div>
         </div>
         <div v-if="hasDefault" class="modal-header">
            <a
               class="btn btn-clear float-right"
               aria-label="Close"
               @click="hideModal"
            />
            <div class="modal-title h5">
               <slot />
            </div>
         </div>
         <div v-if="hasBody" class="modal-body pt-0">
            <a
               v-if="!hasHeader && !hasDefault"
               class="btn btn-clear float-right"
               aria-label="Close"
               @click="hideModal"
            />
            <div class="content">
               <slot name="body" />
            </div>
         </div>
         <div class="modal-footer">
            <button
               v-if="showConfirm"
               class="btn btn-primary mr-2"
               :class="{'loading' : isLoading}"
               @click="confirmModal"
            >
               {{ confirmText }}
            </button>
            <button
               class="btn btn-link"
               @click="hideModal"
            >
               {{ cancelText }}
            </button>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'BaseConfirmModal',
   props: {
      confirmText: {
         type: String,
         default: () => 'Conferma'
      },
      showConfirm: {
         type: Boolean,
         default: () => true
      },
      cancelText: {
         type: String,
         default: () => 'Chiudi'
      },
      isLoading: {
         type: Boolean,
         default: () => false
      },
      size: {
         type: String,
         default: () => 'modal-sm'
      }
   },
   computed: {
      hasHeader () {
         return !!this.$slots.header;
      },
      hasBody () {
         return !!this.$slots.body;
      },
      hasDefault () {
         return !!this.$slots.default;
      }
   },
   methods: {
      confirmModal () {
         this.$emit('confirm');
      },

      hideModal () {
         this.$emit('hide');
      }
   }
};
</script>
<style scoped>
.modal {
  font-size: initial !important;
  white-space: initial !important;
}
</style>
