import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import application from './modules/application.store';
import auth from './modules/auth.store';
import sidebar from './modules/sidebar.store';
import structure from './modules/structure.store';
import socketStatus from './modules/socketStatus.store';
import alarms from './modules/alarms.store';
import problems from './modules/problems.store';

import visibilityChange from './plugins/visibilityChange';

const vuexLocalStorage = new VuexPersist({
   key: 'application', // The key to store the state on in the storage provider.
   storage: window.localStorage,
   reducer: state => ({
      application: state.application,
      structure: state.structure
   })
});

Vue.use(Vuex);

export default new Vuex.Store({
   strict: true,
   modules: {
      application,
      auth,
      sidebar,
      structure,
      socketStatus,
      alarms,
      problems
   },
   plugins: [
      vuexLocalStorage.plugin,
      visibilityChange
   ]
});
