<template>
   <nav
      ref="tree"
      class="main-sidebar sidebar p-0"
      @scroll="onScroll"
   >
      <div class="machine-search">
         <div class="has-icon-right">
            <input
               v-model="searchTerm"
               type="text"
               class="form-input no-border"
               placeholder="Cerca macchina..."
            >
            <i v-if="!searchTerm" class="form-icon ti ti-lg ti-search" />
            <i
               v-else
               class="form-icon c-hand ti ti-lg ti-backspace"
               title="Cancella tutto"
               @click="clearSearch"
            />
         </div>
      </div>
      <div :class="{'is-visible':!isScrolling}" class="hide-shadow" />
      <div v-if="!searchTerm">
         <details
            v-if="unregisteredMachines.length"
            class="accordion cat-node"
            :open="isInActOpen"
         >
            <summary class="accordion-header cat-node-title" @click.prevent="toggleOpen">
               <span class="flex c-hand">
                  <i class="node-icon ti ti-chevron-right ti-lg" :class="{ opened: isInActOpen }" />
                  <span class="text-bold">IN ATTIVAZIONE</span>
               </span>
               <span class="badge" :data-badge="unregisteredMachines.length" />
            </summary>
            <div v-show="isInActOpen" class="accordion-body cat-node-children">
               <ul class="menu menu-nav p-0 pt-1">
                  <SidebarTreeMachine
                     v-for="mac in unregisteredMachines"
                     :key="mac.codMacchina"
                     :machine="mac"
                  />
               </ul>
            </div>
         </details>
         <SidebarTreeCategory
            v-for="category in childCategories"
            :key="category.id_label"
            :category="category"
            :cat-chain="[+category.id_label]"
         />
      </div>
      <div v-else>
         <div class="accordion cat-node" open>
            <input
               id="accordion-1"
               type="checkbox"
               name="accordion-checkbox"
               hidden
            >
            <label class="accordion-header cat-node-title" for="accordion-1">
               <span class="flex c-hand">
                  <i class="node-icon ti ti-chevron-right ti-lg opened" />
                  <span class="text-bold">RISULTATI</span>
               </span>
               <span class="badge" :data-badge="filteredMachines.length" />

            </label>
            <div class="accordion-body cat-node-children">
               <ul v-show="filteredMachines.length" class="menu menu-nav p-0 pt-1">
                  <SidebarTreeMachine
                     v-for="mac in filteredMachines"
                     :key="mac.codMacchina"
                     :machine="mac"
                     :search-term="searchTerm"
                  />
               </ul>
            </div>
         </div>
      </div>
   </nav>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapActions, mapGetters } from 'vuex';
// import SidebarTreeRoot from '@/components/SidebarTreeRoot';
import SidebarTreeCategory from '@/components/SidebarTreeCategory';
import SidebarTreeMachine from '@/components/SidebarTreeMachine';

export default {
   name: 'SidebarTree',
   directives: {
      ClickOutside
   },
   components: {
      // SidebarTreeRoot,
      SidebarTreeCategory,
      SidebarTreeMachine
   },
   data () {
      return {
         isInActOpen: false,
         basePath: process.env.VUE_APP_BASE_PATH,
         level: 1,
         structInterval: null,
         searchTerm: '',
         isMenu: false,
         isScrolling: false
      };
   },
   computed: {
      ...mapGetters({
         machines: 'structure/getMachines',
         getUnregisteredMachines: 'structure/getUnregisteredMachines',
         getCategoriesByLevel: 'structure/getCategoriesByLevel'
      }),
      unregisteredMachines () {
         return this.getUnregisteredMachines;
      },
      childCategories () {
         return this.getCategoriesByLevel(this.level);
      },
      filteredMachines () {
         return this.machines.filter(mac => mac.descMacchina.toUpperCase().search(this.searchTerm.toUpperCase()) >= 0);
      }
   },
   created () {
      this.refreshStructure();

      this.structInterval = setInterval(() => {
         this.refreshStructure();
      }, 30000);
   },
   beforeDestroy () {
      clearInterval(this.structInterval);
   },
   methods: {
      ...mapActions({
         refreshStructure: 'structure/refreshStructure'
      }),
      toggleOpen () {
         this.isInActOpen = !this.isInActOpen;
      },
      showConnectionModal () {
         this.$refs.menu.showConnectionModal();
      },
      closeMenu () {
         if (this.isMenu)
            this.isMenu = false;
      },
      openMenu () {
         if (!this.isMenu)
            this.isMenu = true;
      },
      clearSearch () {
         this.searchTerm = '';
      },
      onScroll () {
         this.isScrolling = this.$refs.tree.scrollTop !== 0;
      }
   }
};
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: none;
}

.in-act-node {
  font-size: 11px;
}

.hide-shadow {
  height: 0.4rem;
  position: relative;

  &.is-visible {
    z-index: 10;
    background: $sidebar-bg;
  }
}

.main-sidebar.sidebar {
  left: -300px;
  transition: all 0.2s;
  background: $sidebar-bg;
  overflow: auto;
  box-shadow: 0 50px 3px rgba(0, 0, 0, 0.12);
}

.machine-search {
  position: sticky;
  display: flex;
  align-content: center;
  top: 0;
  height: $header-height;
  box-shadow: 0 1.6px 3px rgba(0, 0, 0, 0.12);
  background: $light-color;
  padding: 0.2rem;
  z-index: 9;

  .has-icon-right {
    display: flex;
    align-items: center;
    width: 100%;

    input {
      padding-right: 1.5rem;
      background-color: $gray-color-light;
      font-size: 1.1em;
      border-radius: $border-radius;

      &:focus {
        background-color: transparent;
        border-color: $primary-color;
      }
    }
  }

  .form-icon {
    right: 0.3rem;
  }
}

.sidebar-show .main-sidebar {
  left: $tabbar-width;
}

.sidebar-collapse .main-sidebar {
  width: 0;
}

.sidebar {
  position: fixed;
  height: 100%;
  width: $sidebar-width;
  top: 0;
  background: $body-bg;
  z-index: 8;
}

.sidebar-group-header {
  padding: 0.8rem;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.sidebar-navigation {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - #{$footer-height});
}

.menu {
  transform: none;
}

@media (min-width: 992px) {
  .sidebar.main-sidebar {
    left: $tabbar-width;
  }
}
</style>
