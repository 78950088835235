<template>
   <div
      v-show="isLoading"
      class="loading-layer"
      :style="`background: rgba(255, 255, 255, ${opacity})`"
   >
      <div class="loading loading-lg" />
   </div>
</template>

<script>
export default {
   name: 'BaseLoaderLayer',
   props: {
      isLoading: Boolean,
      opacity: {
         type: Number,
         default: 0.5
      }
   }
};
</script>

<style scoped>
.loading-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
</style>
