import { httpClient } from '@/libs/httpClient';

export default class {
   static getUsersList (params) {
      return httpClient.get('/api/users/', { params });
   }

   static getGroupsList () {
      return httpClient.get('/api/users/groups');
   }

   static getUsersCount () {
      return httpClient.get('/api/users/count');
   }

   static addUser (params) {
      return httpClient.post('/api/users/', params);
   }

   static updateUser (params) {
      return httpClient.put('/api/users/', params);
   }

   static deleteUser (params) {
      return httpClient.delete(`/api/users/${params.id}/${params.username}`);
   }
};
