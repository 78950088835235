<template>
   <div id="wrapper">
      <div id="main-content">
         <slot />
      </div>
   </div>
</template>
<script>

export default {
   name: 'LoginPage'
};
</script>

<style lang="scss" scoped>
  #wrapper {
    padding-bottom: 0;
  }

  #main-content {
    background-color: color-mix(in srgb, var(--primary-color), #000 60%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 0;
  }

  @media (min-width: 992px) {
    #main-content {
      padding-left: 0;
    }
  }
</style>
