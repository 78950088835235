import { httpClient } from '@/libs/httpClient';

export default class {
   static handleProblem ({ problemID, note }) {
      return httpClient.put(`/api/problems/handle/${problemID}`, { note });
   }

   static handleMultipleProblems (problems, note) {
      return httpClient.put('/api/problems/multiplehandle/', { problems, note });
   }

   static getProblemsList (params) {
      return httpClient.get('/api/problems/history', {
         params
      });
   }

   static getProblemsCount (params) {
      return httpClient.get('/api/problems/history/count', {
         params
      });
   }

   static getOpenedProblems () {
      return httpClient.get('/api/problems/opened');
   }
};
