<template>
   <BaseMenu :click-event="clickEvent" @close-context="$emit('close-menu')">
      <div class="context-element" @click.prevent.stop="">
         <span class="d-flex"><i class="ti ti-external-link pr-1" /> Monitor</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click.stop="$emit('close-menu')">
            <div class="context-element" @click.stop="$emit('connection-list-monitor')">
               <span class="d-flex"><i class="ti ti-external-link pr-1" /> Lista Connessioni</span>
            </div>
            <div class="context-element" @click.stop="$emit('total-connection-monitor')">
               <span class="d-flex"><i class="ti ti-external-link pr-1" /> Totali Connessioni</span>
            </div>
            <div class="context-element" @click.stop="$emit('simultaneous-monitor')">
               <span class="d-flex"><i class="ti ti-external-link pr-1" /> Simultanee</span>
            </div>
         </div>
      </div>
      <div class="context-element" @click.prevent.stop="">
         <span class="d-flex"><i class="ti ti-search pr-1" /> Ricerche</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click.stop="$emit('close-menu')">
            <router-link :to="{name: 'lista-connessioni', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-list-search pr-1" /> Lista Connessioni</span>
            </router-link>
            <router-link :to="{name: 'lista-simultanee', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-list-search pr-1" /> Lista Simultanee</span>
            </router-link>
         </div>
      </div>
      <div class="context-element" @click.prevent.stop="">
         <span class="d-flex"><i class="ti ti-report pr-1" /> Report</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click.stop="$emit('close-menu')">
            <router-link :to="{name: 'report-connessioni', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Connessioni</span>
            </router-link>
            <router-link :to="{name: 'report-connessioni-ora', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-chart-line pr-1" /> Connessioni/Ora</span>
            </router-link>
            <router-link :to="{name: 'report-pagamenti-ora', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-chart-line pr-1" /> Pagamenti/Ora</span>
            </router-link>
            <router-link :to="{name: 'report-transazioni', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Transazioni</span>
            </router-link>
            <router-link :to="{name: 'report-simultanee-ora', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Simultanee Orarie</span>
            </router-link>
            <router-link :to="{name: 'report-simultanee-minuto', query: { macID: machine.codMacchina }}" class="context-element">
               <span class="d-flex"><i class="ti ti-table pr-1" /> Simultanee al Minuto</span>
            </router-link>
         </div>
      </div>
      <div
         v-if="userRole === 1"
         class="context-element"
         @click.prevent.stop=""
      >
         <span class="d-flex"><i class="ti ti-terminal pr-1" /> Comandi</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click.stop="$emit('close-menu')">
            <div
               v-for="cmd in commands"
               :key="cmd.codComandoSrv"
               class="context-element"
               @click="$emit('show-confirm-command', cmd)"
            >
               <span class="d-flex"><i class="ti ti-terminal-2 pr-1" /> {{ cmd.descComando }}</span>
            </div>
         </div>
      </div>
      <div class="context-element" @click.prevent.stop="">
         <span class="d-flex"><i class="ti ti-tool pr-1" /> Gestione</span>
         <i class="ti ti-chevron-right pl-1" />
         <div class="context-submenu" @click.stop="$emit('close-menu')">
            <div class="context-element" @click.stop="$emit('show-info-modal')">
               <span class="d-flex"><i class="ti ti-info-circle pr-1" /> Info macchina</span>
            </div>
            <div
               v-if="userRole === 1"
               class="context-element"
               @click.stop="$emit('show-edit-modal')"
            >
               <span class="d-flex"><i class="ti ti-edit pr-1" /> Modifica macchina</span>
            </div>
            <div
               v-if="userRole === 1"
               class="context-element"
               @click.stop="$emit('show-delete-modal')"
            >
               <span class="d-flex"><i class="ti ti-trash pr-1" /> Elimina macchina</span>
            </div>
         </div>
      </div>
   </BaseMenu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Structure from '@/api/Structure';
import BaseMenu from '@/components/BaseMenu';

export default {
   name: 'SidebarTreeMachineMenu',
   components: {
      BaseMenu
   },
   props: {
      clickEvent: [MouseEvent, null],
      machine: Object,
      catChain: Array
   },
   data () {
      return {
         basePath: process.env.VUE_APP_BASE_PATH,
         isCmdConfirmModal: false,
         localEnabled: false,
         customersList: [],
         commands: [],
         filters: {
            port: '',
            tid: '',
            origin: ''
         },
         selectedService: '',
         includeService: '1'
      };
   },
   computed: {
      ...mapGetters({
         services: 'structure/getServices'
      }),
      chainString () {
         return this.catChain.join(',');
      },
      userRole () {
         return this.$store.state.auth.group;
      }
   },
   created () {
      if (this.userRole === 1)
         this.getCommands();
   },
   methods: {
      ...mapActions({
         refreshStructure: 'structure/refreshStructure'
      }),
      openSubMenu (sub) {
         if (this.isSubMenu !== sub)
            this.isSubMenu = sub;
         else
            this.isSubMenu = '';
      },
      hideErrorModal () {
         this.errorMsg = '';
      },
      openConnectionMonitor () {
         const url = `${this.basePath}monitor/?mac=${this.machine.codMacchina}&type=conn&opt=ori:${this.filters.origin},tid:${this.filters.tid},vendor:${this.filters.port},type:${this.selectedService},inc:${this.includeService}`;

         const windowName = `Connessioni-${this.machine.codMacchina}${this.filters.origin}${this.filters.tid}${this.filters.port}`;
         const specs = 'location=0, menubar=0, scrollbars=1, titlebar=0, status=0, width=1350, height=400';
         window.open(url, windowName, specs);
      },
      openTotConnMonitor () {
         const url = `${this.basePath}monitor/?mac=${this.machine.codMacchina}&type=mactot`;

         const windowName = `TotaleConnessioni-${this.machine.codMacchina}`;
         const specs = 'location=0, menubar=0, scrollbars=1, titlebar=0, status=0, width=1350, height=400';
         window.open(url, windowName, specs);
      },
      openSimMonitor () {
         const url = `${this.basePath}monitor/?mac=${this.machine.codMacchina}&type=sim`;

         const windowName = `Simultanee-${this.machine.codMacchina}`;
         const specs = 'location=0, menubar=0, scrollbars=1, titlebar=0, status=0, width=1350, height=400';
         window.open(url, windowName, specs);
      },
      hideConnectionModal () {
         this.isConnectionModal = false;
      },
      async getCommands () {
         const apiRes = await Structure.getCommands();

         if (apiRes === 'timeout')
            this.$store.dispatch('showAlertModal', 'Timeout del server!');
         else {
            const { status, data } = apiRes;

            switch (status) {
               case 200:
                  this.commands = data;
                  break;
               case 404:
                  break;
               case 500:
                  this.$store.dispatch('showAlertModal', 'Errore del server!');
                  break;
               default:
                  break;
            }
         }
      }
   }
};
</script>
