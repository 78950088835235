import { httpClient } from '@/libs/httpClient';

export default class {
   static getCategories () {
      return httpClient.get('/api/frontend/categories');
   }

   static getCategoriesByLevel (level) {
      return httpClient.get(`/api/frontend/categories/${level}`);
   }

   static getConnectors () {
      return httpClient.get('/api/connectors/');
   }

   static getLabels () {
      return httpClient.get('/api/frontend/labels');
   }

   static getMachines () {
      return httpClient.get('/api/frontend/machines');
   }

   static updateMachine (params) {
      return httpClient.put('/api/frontend/machines', params);
   }

   static deleteMachine (params) {
      return httpClient.delete(`/api/frontend/machines/${params.id}/${params.description}`);
   }

   static getServiceLastMins (label, service) {
      return httpClient.get(`/api/frontend/servicelastmins/${label}/${service}`);
   }

   static getLastHour (catString) {
      return httpClient.get(`/api/frontend/lasthour/${catString}`);
   }

   static getHalfHour (catString, date) {
      return httpClient.get(`/api/frontend/halfhour/${catString}/${date || ''}`);
   }

   static getAvgConnectivities (catString, interval) {
      return httpClient.get(`/api/frontend/connectivities/${catString}/${interval}`);
   }

   static getAttempts (catString, interval) {
      return httpClient.get(`/api/frontend/attempts/${catString}/${interval}`);
   }

   static getTotal (catString) {
      return httpClient.get(`/api/frontend/total/${catString}`);
   }
};
