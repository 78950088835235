<template>
   <div>
      <SidebarTree v-show="selectedSidebar === 'tree'" />
      <SidebarMenu v-show="selectedSidebar !== 'tree'" :menu-name="selectedSidebar" />
   </div>
</template>

<script>
import { mapState } from 'vuex';
import SidebarTree from '@/components/SidebarTree';
import SidebarMenu from '@/components/SidebarMenu';

export default {
   name: 'TheSidebar',
   components: {
      SidebarTree,
      SidebarMenu
   },
   computed: {
      ...mapState({
         selectedSidebar: state => state.application.selectedSidebar
      })
   }
};
</script>
