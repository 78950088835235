
import store from '@/store';
import { httpClient, httpUnandledClient } from '@/libs/httpClient';

export default class {
   static login (username, password) {
      return httpUnandledClient.post('/api/auth/login', {
         username,
         password
      });
   }

   static me () {
      return httpClient.get('/api/auth/me');
   }

   static passwordUpdate (oldPwd, newPwd) {
      return httpClient.post('/api/auth/passwordupdate', {
         oldPwd,
         newPwd
      });
   }

   static logout () {
      store.dispatch('logout');
      return httpClient.get('/api/auth/logout');
   }
};
