<template>
   <div>
      <div v-for="(menuCard, blockName, blockKey) of groupElements" :key="blockKey">
         <div v-if="menuCard.elements" class="accordion">
            <input
               :id="`accordion-${groupKey}-${blockName}`"
               type="checkbox"
               name="accordion-checkbox"
               hidden
            >
            <label class="accordion-header sidebar-menu-header c-hand no-select" :for="`accordion-${groupKey}-${blockName}`">
               <span class="sidebar-menu-header-title">
                  <i class="ti ti-md mr-1 sidebar-menu-icon" :class="`ti-${menuCard.icon}`" />
                  <span>{{ menuCard.name }}</span>
               </span>
               <i class="ti ti-md ti-chevron-left sidebar-toggle-arrow" />
            </label>
            <div class="accordion-body mb-1">
               <div :id="menuCard.name" class="sidebar-list-group">
                  <ul class="menu menu-nav">
                     <li
                        v-for="(element, name, key) of menuCard.elements"
                        :key="key"
                        class="menu-item sidebar-list-item"
                     >
                        <a
                           v-if="element.target === 'new'"
                           class="sidebar-link-title"
                           @click="openNewWindow(element.url)"
                        >
                           <i class="ti ti-md ti-chevron-right" />
                           <span>{{ element.title }}</span>
                        </a>
                        <router-link
                           v-else
                           :to="element.url"
                           class="sidebar-link-title"
                           :target="element.target"
                        >
                           <i class="ti ti-md ti-chevron-right" />
                           <span>{{ element.title }}</span>
                        </router-link>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div v-else class="mb-1">
            <a
               v-if="menuCard.target === 'new'"
               class="sidebar-link-title sidebar-direct-link"
               @click="openNewWindow(menuCard.url)"
            >
               <div class="d-flex c-hand no-select">
                  <span class="sidebar-menu-header-title">
                     <i class="ti ti-md mr-1 sidebar-menu-icon" :class="`ti-${menuCard.icon}`" />
                     <span>{{ menuCard.name }}</span>
                  </span>
               </div>
            </a>
            <router-link
               v-else
               :to="menuCard.url"
               class="sidebar-link-title sidebar-direct-link"
            >
               <div class="d-flex c-hand no-select">
                  <span class="sidebar-menu-header-title">
                     <i class="ti ti-md mr-1 sidebar-menu-icon" :class="`ti-${menuCard.icon}`" />
                     <span>{{ menuCard.name }}</span>
                  </span>
               </div>
            </router-link>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'SidebarGroup',
   props: {
      groupElements: Array,
      groupKey: Number
   },
   methods: {
      openNewWindow (url) {
         const mWidth = 1280;
         const mHeight = 700;
         const mLeft = (window.innerWidth / 2) - (mWidth / 2);
         const mTop = (window.innerHeight / 2) - (mHeight / 2);
         window.open(url, 'monitor-clienti', `width=${mWidth},height=${mHeight},left=${mLeft},top=${mTop},toolbar=no,location=no`);
      }
   }
};
</script>

<style lang="scss" scoped>
  .sidebar-menu-header {
    border-radius: $border-radius;
    margin: 0 0.25rem;
  }

  .sidebar-menu-header:hover,
  .sidebar-direct-link:hover {
    background: rgba($tabbar-bg, 0.2);
    color: $tabbar-bg;
  }

  .sidebar-menu-header-title {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .sidebar-menu-header-title > span,
  .sidebar-link-title {
    transition: opacity 0.2s;
  }

  .sidebar-list-group {
    position: relative;
    padding-left: 1.15rem !important;

    .menu {
      transform: none;
    }
  }

  .main-sidebar {
    .sidebar-link-title > i {
      display: none;
    }

    .sidebar-list-group .menu {
      transition: padding 0.2s;
      padding: 0.25rem;
      padding-left: 0.4rem;
    }
  }

  .sidebar-toggle-arrow {
    transition: transform 0.3s;
    opacity: 0.5;
  }

  .accordion input:checked ~ .accordion-header .sidebar-toggle-arrow {
    transform: rotate(-90deg);
    transition: transform 0.1s;
  }

  .sidebar-list-group .sidebar-list-item {
    margin-left: 0.2rem;
  }

  .sidebar-list-group::before {
    content: '';
    height: 100%;
    opacity: 1;
    width: 3px;
    background: $secondary-color;
    position: absolute;
    left: 1.4rem;
    top: 0;
    border-radius: 15px;
    z-index: 1;
  }

  .sidebar-list-item > .router-link-active,
  .sidebar-direct-link.router-link-active,
  .sidebar-list-item > a:hover {
    background: var(--primary-color) !important;
    color: $light-color !important;
    position: relative;
  }

  .sidebar-menu-icon {
    opacity: 0.5;
  }

  .router-link-active .sidebar-menu-icon {
    opacity: 1;
  }

  .sidebar-navigation .sidebar-direct-link {
    border-radius: $border-radius;
    margin: 0 0.25rem;
    text-decoration: none !important;
  }

  .sidebar-navigation .accordion .accordion-header,
  .sidebar-navigation .sidebar-direct-link {
    display: flex;
    padding: 0.1rem 0.4rem 0.1rem 0.7rem;
    justify-content: space-between;
    align-items: center;
  }
</style>
