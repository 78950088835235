import axios from 'axios';
import Auth from '@/api/Auth';
import router from '@/routes';

axios.defaults.baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH;
axios.defaults.timeout = process.env.VUE_APP_HTTP_TIMEOUT;
axios.defaults.withCredentials = true;

/**
* Istanza di Axios con interceptors
*/
const httpClient = axios.create();

httpClient.interceptors.response.use(
   response => {
      return response;
   },
   async err => {
      if (err.code === 'ECONNABORTED' || err.response === undefined)
         return 'timeout';
      else if (err.response.status === 401) { // Forbidden
         await Auth.logout();
         router.push('/login').catch(() => null);
      }
      else
         return err.response;
   }
);

httpClient.interceptors.request.use(
   config => {
      const token = localStorage.getItem('token');
      if (token) config.headers.Authorization = token;
      return config;
   },
   err => Promise.reject(err)
);

/**
* Istanza di Axios senza interceptors
*/
const httpUnandledClient = axios.create();

export { httpClient, httpUnandledClient };
