<template>
   <div id="wrapper">
      <portal-target name="wrapper" />
      <slot />
   </div>
</template>
<script>

export default {
   name: 'FullPage'
};
</script>
<style scoped>
#wrapper {
  padding-bottom: 0;
}
</style>
