import Vue from 'vue';
import Router from 'vue-router';
import VueSocketIO from 'vue-socket.io';
import store from '@/store';

Vue.use(Router);

let entryUrl = null;

/**
 * Apre websocket
 *
 */
function connectWebsocket () {
   const token = localStorage.getItem('token');
   const extraHeaders = {};
   if (token)
      extraHeaders.Authorization = token;

   const options = {
      path: `/${process.env.VUE_APP_API_PATH}ws/`,
      secure: true,
      reconnection: true,
      reconnectionDelay: 1000,
      transports: ['polling'],
      useConnectionNamespace: true,
      transportOptions: {
         polling: {
            extraHeaders
         }
      }
   };

   if (!Vue.prototype.$socket) {
      Vue.use(new VueSocketIO({
         debug: false,
         connection: `${process.env.VUE_APP_API_URL}alarms`,
         vuex: {
            store,
            actionPrefix: 'socket_'
         },
         options
      }));

      Vue.use(new VueSocketIO({
         debug: false,
         connection: `${process.env.VUE_APP_API_URL}mac_alarms`,
         vuex: {
            store,
            actionPrefix: 'socket_'
         },
         options
      }));

      Vue.use(new VueSocketIO({
         debug: false,
         connection: `${process.env.VUE_APP_API_URL}problems`,
         vuex: {
            store,
            actionPrefix: 'socket_'
         },
         options
      }));
   }
}

/**
 * Chiude websocket
 *
 */
function clearWebsocket () {
   if (Vue.prototype.$socket) {
      for (const nsp of Object.keys(Vue.prototype.$socket))
         Vue.prototype.$socket[nsp].close();

      delete Vue.prototype.$socket;
   }
}

/**
 * Controllo autorizzazione
 *
 */
const checkAuth = async (to, from, next) => {
   if (store.getters.isAuthenticated) { // Se autenticato sullo store
      connectWebsocket();

      if (entryUrl) {
         const url = entryUrl;
         entryUrl = null;
         return next(url);
      }
      else
         return next();
   }

   // Controlla se c'è il token
   await store.dispatch('checkAuth');

   if (store.getters.isAuthenticated) {
      connectWebsocket();
      next();
   }
   else {
      entryUrl = to.path; // store entry url before redirect
      next('/login');
   }
};

/**
 * Lazy loading pagine
 *
 * @param {String} view
 * @returns
 */
function lazyLoad (view) {
   return () => import(/* webpackChunkName: "[request]" */`@/views/${view}`);
}

const router = new Router({
   base: process.env.VUE_APP_BASE_PATH,
   mode: 'history',
   // eslint-disable-next-line no-sparse-arrays
   routes: [
      {
         path: '/dashboard/:dashId?',
         name: 'dashboard',
         component: lazyLoad('Dashboard'),
         meta: { layout: 'DefaultPage' },
         props: true,
         beforeEnter: checkAuth
      },

      {
         path: '/tools/connessioni/',
         name: 'lista-connessioni',
         component: lazyLoad('ToolsListaConnessioni'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/extractconnessioni/',
         name: 'extract-lista-connessioni',
         component: lazyLoad('ToolsEstrazioneListaConnessioni'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/extractsimultanee/',
         name: 'extract-lista-simultanee',
         component: lazyLoad('ToolsEstrazioneListaSimultanee'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/extracttidnodo/',
         name: 'extract-tid-nodo',
         component: lazyLoad('ToolsEstrazioneTidPerNodo'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/extractnoditid/',
         name: 'extract-nodi-tid',
         component: lazyLoad('ToolsEstrazioneNodiPerTid'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/simultanee/',
         name: 'lista-simultanee',
         component: lazyLoad('ToolsListaSimultanee'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/monitor/connessioni',
         name: 'tools-monitor-connessioni',
         component: lazyLoad('ToolsMonitorConnessioni'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/monitor/connessioni-totali',
         name: 'monitor-connessioni-totali',
         component: lazyLoad('ToolsMonitorTotaliConnessioni'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/monitor/simultanee',
         name: 'tools-monitor-simultanee',
         component: lazyLoad('ToolsMonitorSimultanee'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/machines',
         name: 'tools-machines',
         component: lazyLoad('ToolsListaMacchine'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/tools/monitor/tentativi',
         name: 'tools-monitor-tentativi',
         component: lazyLoad('ToolsMonitorTentativi'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/connessioni/',
         name: 'report-connessioni',
         component: lazyLoad('ToolsReportConnessioni'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/connessioni-ora/',
         name: 'report-connessioni-ora',
         component: lazyLoad('ToolsReportConnessioniOra'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/chiamate-ora/',
         name: 'report-chiamate-ora',
         component: lazyLoad('ToolsReportChiamateOra'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/pagamenti-ora/',
         name: 'report-pagamenti-ora',
         component: lazyLoad('ToolsReportPagamentiOra'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/transazioni/',
         name: 'report-transazioni',
         component: lazyLoad('ToolsReportTransazioni'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/transazioni-tipo/',
         name: 'report-transazioni-tipo',
         component: lazyLoad('ToolsReportTipoTransazioni'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/simultanee-ora/',
         name: 'report-simultanee-ora',
         component: lazyLoad('ToolsReportSimultanee'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/simultanee-minuto/',
         name: 'report-simultanee-minuto',
         component: lazyLoad('ToolsReportSimultaneeMinuto'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/connettori-ora/',
         name: 'report-connettori-ora',
         component: lazyLoad('ToolsReportConnettori'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/reports/monthly/',
         name: 'report-mensile',
         component: lazyLoad('ReportsMonthly'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },

      {
         path: '/manage/alarms/',
         name: 'manage-alarms',
         component: lazyLoad('ManageAlarms'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/conn-alarms/',
         name: 'manage-alarms-conn',
         component: lazyLoad('ManageAlarmsConnectivity'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/cliconn-alarms/',
         name: 'manage-alarms-conn-cli',
         component: lazyLoad('ManageAlarmsConnectivityCustomer'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/gt-alarms/',
         name: 'manage-alarms-gt',
         component: lazyLoad('ManageAlarmsGT'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/holding-alarms/',
         name: 'manage-alarms-holding',
         component: lazyLoad('ManageAlarmsHolding'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/mac-alarms/',
         name: 'manage-alarms-mac',
         component: lazyLoad('ManageAlarmsMachines'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/alarm-emails/',
         name: 'manage-alarm-emails',
         component: lazyLoad('ManageAlarmEmails'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/transaction-types/',
         name: 'manage-transaction-types',
         component: lazyLoad('ManageTransactions'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/customers/',
         name: 'manage-customers',
         component: lazyLoad('ManageCustomers'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/global-message/',
         name: 'manage-global-message',
         component: lazyLoad('ManageGlobalMessage'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/alarm-sms/',
         name: 'alarm-sms',
         component: lazyLoad('ManageSmsSettings'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },

      // KPI
      {
         path: '/kpi/nodes-month/',
         name: 'kpi-nodes-month',
         component: lazyLoad('KPINodoMensile'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/kpi/receipts-month/',
         name: 'kpi-receipts-month',
         component: lazyLoad('KPIScontriniMensile'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/kpi/nodes-year/',
         name: 'kpi-nodes-year',
         component: lazyLoad('KPINodoAnnuale'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },

      // Finestre dei monitor
      {
         path: '/monitor',
         name: 'monitor',
         component: lazyLoad('MonitorListaConnessioni'),
         meta: {
            layout: 'FullPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/monitor-connessioni',
         name: 'monitor-connessioni',
         component: lazyLoad('MonitorTotaliConnessioni'),
         meta: {
            layout: 'FullPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/monitor-connessioni-nodo',
         name: 'monitor-connessioni-nodo',
         component: lazyLoad('MonitorTotaliConnessioniNodo'),
         meta: {
            layout: 'FullPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/monitor-simultanee',
         name: 'monitor-simultanee',
         component: lazyLoad('MonitorSimultanee'),
         meta: {
            layout: 'FullPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/notifications',
         name: 'monitor-notifiche',
         component: lazyLoad('NotificationsWindow'),
         meta: {
            layout: 'FullPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/dashboard-window/:dashId?',
         name: 'dashboard-window',
         component: lazyLoad('DashboardWindow'),
         meta: {
            layout: 'FullPage'
         },
         props: true,
         beforeEnter: checkAuth
      },

      {
         path: '/profile',
         name: 'profile',
         component: lazyLoad('Profile'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/users',
         name: 'utenti',
         component: lazyLoad('Users'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/logs/history',
         name: 'log utenti',
         component: lazyLoad('LogsHistory'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/logs/commands',
         name: 'log comandi',
         component: lazyLoad('LogsCommands'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/alarms/manage',
         name: 'gestione allarmi',
         component: lazyLoad('AlarmsManage'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/alarms/history',
         name: 'log allarmi',
         component: lazyLoad('AlarmsHistory'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/alarms/mac-history',
         name: 'log allarmi macchina',
         component: lazyLoad('AlarmsMachinesHistory'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/problems/manage',
         name: 'gestione problemi',
         component: lazyLoad('ProblemsManage'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/problems/mac-history',
         name: 'log problemi',
         component: lazyLoad('ProblemsHistory'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },

      // FEPVIEW
      {
         path: '/structure/holding/:group',
         name: 'holding',
         component: lazyLoad('StructureHolding'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/structure/catene/:group/:parentID?',
         name: 'catene',
         component: lazyLoad('StructureCatene'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/structure/negozi/:group/:parentID?',
         name: 'negozi',
         component: lazyLoad('StructureNegozi'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/structure/pos/:group/:parentID?',
         name: 'pos',
         component: lazyLoad('StructurePOS'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/structure/reports/:group',
         name: 'report-feptiew',
         component: lazyLoad('ToolsReportFepview'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/monitor/:level/:id',
         name: 'monitor-fepview',
         component: lazyLoad('MonitorFepview'),
         meta: {
            layout: 'FullPage'
         },
         props: true,
         beforeEnter: checkAuth
      },

      {
         path: '/manage/holding/:group',
         name: 'manage-holding',
         component: lazyLoad('ManageHolding'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/catene/:group',
         name: 'manage-catene',
         component: lazyLoad('ManageCatene'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/negozi/:group',
         name: 'manage-negozi',
         component: lazyLoad('ManageNegozi'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/pos/:group',
         name: 'manage-pos',
         component: lazyLoad('ManagePOS'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/add-pos/:group',
         name: 'add-pos',
         component: lazyLoad('StructureCensimentoAnagrafica'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },
      {
         path: '/manage/delete-pos/:group',
         name: 'delete-pos',
         component: lazyLoad('StructureEliminazionePOS'),
         meta: {
            layout: 'DefaultPage'
         },
         props: true,
         beforeEnter: checkAuth
      },

      {
         path: '/login',
         name: 'login',
         component: lazyLoad('Login'),
         meta: {
            layout: 'LoginPage'
         },
         beforeEnter: (to, from, next) => {
            if (store.getters.isAuthenticated)
               return next('/');
            else {
               clearWebsocket();
               return next();
            }
         }
      },
      {
         path: '/404',
         name: 'Page404',
         component: lazyLoad('Page404'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      {
         path: '/plain',
         name: 'PlainPage',
         component: lazyLoad('PlainPage'),
         meta: {
            layout: 'DefaultPage'
         },
         beforeEnter: checkAuth
      },
      // Redirect
      {
         path: '/',
         redirect: { name: 'dashboard' }
      },
      {
         path: '*',
         redirect: { name: 'Page404' }
      }
   ]
});

// Loading Animation
router.beforeEach((to, from, next) => {
   store.dispatch('setLoadingStatus', true);
   next();
});

router.afterEach(() => {
   store.dispatch('setLoadingStatus', false);
});

export default router;
