<template>
   <component :is="layout">
      <transition name="fade" mode="out-in">
         <router-view :key="$route.fullPath" />
      </transition>
      <BaseAlertModal v-if="isAlertModal" @hide="hideAlertModal">
         <template slot="header">
            <span class="p-vcentered"><i class="ti ti-lg ti-alert-triangle pr-2 pt-1" /> Errore!</span>
         </template>
         <div slot="body" v-html="alertMsg" />
      </BaseAlertModal>
   </component>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LoginPage from '@/layouts/LoginPage.vue';
import DefaultPage from '@/layouts/DefaultPage.vue';
import FullPage from '@/layouts/FullPage.vue';
import BaseAlertModal from '@/components/BaseAlertModal.vue';
import Settings from '@/api/Settings';

export default {
   name: 'App',
   components: {
      LoginPage,
      DefaultPage,
      FullPage,
      BaseAlertModal
   },
   computed: {
      ...mapState({
         isAlertModal: state => state.application.isAlertModal,
         alertMsg: state => state.application.alertMsg
      }),
      layout () {
         return (this.$route.meta.layout);
      }
   },
   async created () {
      const { data: titleRequest, status: titleStatus } = await Settings.getAppSettingsPublic('env_name');
      const { data: colorRequest, status: colorStatus } = await Settings.getAppSettingsPublic('primary_color');
      const { data: loginModeRequest, status: loginModeStatus } = await Settings.getAppSettingsPublic('login_mode');

      if (titleStatus === 200)
         this.setAppName(titleRequest);

      if (loginModeStatus === 200)
         this.setLoginMode(loginModeRequest);

      if (colorStatus === 200)
         document.querySelector(':root').style.setProperty('--primary-color', colorRequest);
   },
   methods: {
      ...mapActions([
         'hideAlertModal',
         'setAppName',
         'setLoginMode'
      ])
   }
};
</script>

<style>
  html,
  body {
    height: 100%;
  }
</style>
