import { httpClient } from '@/libs/httpClient';

export default class {
   // Allarmi sui componenti
   static getAlarmsList (params) {
      return httpClient.get('/api/alarms/history', {
         params
      });
   }

   static getAlarmsCount (params) {
      return httpClient.get('/api/alarms/history/count', {
         params
      });
   }

   static getOpenedAlarms () {
      return httpClient.get('/api/alarms/opened');
   }

   static getConfig (group) {
      return httpClient.get('/api/alarms/config', {
         params: { group }
      });
   }

   static addConfig (params) {
      return httpClient.post('/api/alarms/config', params);
   }

   static updateConfig (params) {
      return httpClient.put('/api/alarms/config', params);
   }

   static deleteConfig (params) {
      return httpClient.delete(`/api/alarms/config/${params.id}/${params.description}`);
   }

   static getTypes () {
      return httpClient.get('/api/alarms/types');
   }

   static getTimeSlots () {
      return httpClient.get('/api/alarms/slots');
   }

   // Gestione allarmi macchina

   static handleAlarm ({ alarmID, note }) {
      return httpClient.put(`/api/alarms/handle/${alarmID}`, { note });
   }

   static handleMultipleAlarms (alarms, note) {
      return httpClient.put('/api/alarms/multiplehandle/', { alarms, note });
   }

   static closeMultipleAlarms (alarms, note) {
      return httpClient.put('/api/alarms/multipleclose/', { alarms, note });
   }

   // Configurazione allarmi macchina
   static getMachineAlarms (params) {
      return httpClient.get('/api/alarms/machines', { params });
   }

   static getMachineAlarmsCount (params) {
      return httpClient.get('/api/alarms/machines/count', { params });
   }

   static updateMachineAlarm (params) {
      return httpClient.put('/api/alarms/machines', params);
   }

   static deleteMachineAlarm (params) {
      return httpClient.delete(`/api/alarms/machines/${params.id}/${params.code}/`);
   }

   // Storico allarmi macchina
   static getAlarmsMacList (params) {
      return httpClient.get('/api/alarms/mac-history', { params });
   }

   static getAlarmsMacCount (params) {
      return httpClient.get('/api/alarms/mac-history/count', {
         params
      });
   }

   static getOpenedMacAlarms () {
      return httpClient.get('/api/alarms/mac-opened');
   }

   // Destinatari allarmi
   static getEmails () {
      return httpClient.get('/api/alarms/emails');
   }

   static addEmail (params) {
      return httpClient.post('/api/alarms/emails', params);
   }

   static updateEmail (params) {
      return httpClient.put('/api/alarms/emails', params);
   }

   static deleteEmail (params) {
      return httpClient.delete(`/api/alarms/emails/${params.id}/${params.userid}`);
   }

   static getEmailGroups () {
      return httpClient.get('/api/alarms/emails/groups');
   }
};
