import Settings from '@/api/Settings';

export default {
   namespaced: false,
   strict: true,
   state: {
      appName: process.env.VUE_APP_TITLE,
      isLoading: false,
      isAlertModal: false,
      alertMsg: '',
      selectedSidebar: 'tree',
      login_mode: process.env.VUE_APP_LOGIN_MODE,
      dashboard_settings: [],
      is_visible: true
   },
   getters: {
      getSettingByDashboard: state => dash => state.dashboard_settings.find(d => d.code === dash),
      getDashboards: state => state.dashboard_settings,
      getAppName: state => state.appName,
      getLoginMode: state => state.login_mode,
      getVisibility: state => state.is_visible
   },
   mutations: {
      SET_APP_NAME (state, payload) {
         state.appName = payload;
      },
      SET_LOGIN_MODE (state, payload) {
         state.login_mode = payload;
      },
      SET_LOADING_STATUS (state, payload) {
         state.isLoading = payload;
      },
      HIDE_ALERT_MODAL (state) {
         state.isAlertModal = false;
         state.alertMsg = '';
      },
      SHOW_ALERT_MODAL (state, payload) {
         state.isAlertModal = true;
         state.alertMsg = payload;
      },
      SELECT_SIDEBAR (state, sidebar) {
         state.selectedSidebar = sidebar;
      },
      SET_DASHBOARD_SETTINGS (state, payload) {
         state.dashboard_settings = payload;
      },
      CHANGE_VISIBILITY_STATUS (state, payload) {
         state.is_visible = payload;
      }
   },
   actions: {
      setAppName ({ commit }, payload) {
         commit('SET_APP_NAME', payload);
      },
      setLoginMode ({ commit }, payload) {
         commit('SET_LOGIN_MODE', payload);
      },
      setLoadingStatus ({ commit }, payload) {
         commit('SET_LOADING_STATUS', payload);
      },
      hideAlertModal ({ commit }) {
         commit('HIDE_ALERT_MODAL');
      },
      showAlertModal ({ commit }, payload) {
         commit('SHOW_ALERT_MODAL', payload);
      },
      selectSidebar ({ commit }, sidebar) {
         commit('SELECT_SIDEBAR', sidebar);
      },
      async updateDashboardSettings ({ commit }) {
         const { status, data } = await Settings.getAppSettings('dashboards');
         if (status === 200) {
            const dashArr = Object.keys(data).map(key => {
               return {
                  ...data[key],
                  code: key
               };
            });
            commit('SET_DASHBOARD_SETTINGS', dashArr);
         }
         else
            console.log('Errore', status);
      }
   }
};
