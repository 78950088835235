import { httpClient } from '@/libs/httpClient';

export default class {
   static getDashboardSettings () {
      return httpClient.get('/api/settings/dashboards');
   }

   static getAppSettings (option) {
      return httpClient.get(`/api/settings/options/${option}`);
   }

   static getAppSettingsPublic (option) {
      return httpClient.get(`/api/public/settings/options/${option}`);
   }

   static updateGlobalMessage (params) {
      return httpClient.put('/api/settings/globalMessage', params);
   }

   static updateSmsSettings (params) {
      return httpClient.put('/api/settings/sms', params);
   }
};
