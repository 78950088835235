export default {
   namespaced: true,
   strict: true,
   state: {
      alarms: [],
      macAlarms: [],
      configs: [],
      dashboard_alarms: []
   },
   getters: {
      getAlarms: state => state.alarms,
      getConfigurations: state => state.configs,
      getConfigurationsById: state => id => state.configs.filter(conf => conf.id_elemento === id),
      sortedAlarms (state) {
         function compare (a, b) {
            if (a.codAllarmeA > b.codAllarmeA) return -1;
            if (a.codAllarmeA < b.codAllarmeA) return 1;
            return 0;
         }

         return [...state.macAlarms].sort(compare);
      },
      count: state => state.macAlarms.length,
      getProblemsByMachine: state => macId => state.macAlarms.filter(el => el.codMacchina === macId)
   },
   mutations: {
      UPDATE_ALARMS (state, payload) {
         state.alarms = payload;
      },
      UPDATE_MAC_ALARMS (state, payload) {
         state.macAlarms = payload;
      },
      SET_CONFIGURATIONS (state, payload) {
         state.configs = payload;
      },
      PUT_DASHBOARD_ALARM (state, { type, customer, connectivity, description }) {
         const isAlarmExists = state.dashboard_alarms.some(alarm => alarm.type === type && alarm.customer === customer && alarm.connectivity === connectivity);

         if (!isAlarmExists)
            state.dashboard_alarms = [...state.dashboard_alarms, { type, customer, connectivity, description }];
      },
      REMOVE_DASHBOARD_ALARM (state, { type, customer, connectivity }) {
         const delIndex = state.dashboard_alarms.findIndex(alarm => alarm.type === type && alarm.customer === customer && alarm.connectivity === connectivity);
         state.dashboard_alarms = state.dashboard_alarms.filter((alarm, index) => index !== delIndex);
      }
   },
   actions: {
      setConfigurations ({ commit }, payload) {
         commit('SET_CONFIGURATIONS', payload);
      },
      putDashboardAlarm ({ commit }, payload) {
         commit('PUT_DASHBOARD_ALARM', payload);
      },
      deleteDashboardAlarm ({ commit }, payload) {
         commit('REMOVE_DASHBOARD_ALARM', payload);
      },
      socket_alarms ({ commit }, data) {
         commit('UPDATE_ALARMS', data);
      },
      socket_mac_alarms ({ commit }, data) {
         commit('UPDATE_MAC_ALARMS', data);
      }
   }
};
